import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Field, FieldGroup, Fieldset, Label, Legend } from '../components/fieldset';
import { Switch } from '../components/switch';
import { Text } from '../components/text';
import { updateBotSettings } from '../actions';
import { Heading } from '../components/heading';
import PremiumBadge from '../components/PremiumBadge';
import PremiumModal from '../components/PremiumModal';
export class ExtraPages extends Component {
    handleToggle = (pageName) => {
        const currentPages = this.props.bot.extra_pages || {};
        const updatedPages = {
            ...currentPages,
            [pageName]: !currentPages[pageName]
        };
        this.props.updateBotSettings('extra_pages', updatedPages);
    };

    render() {
        const { extra_pages = {} } = this.props.bot;

        return (
            <Dashboard >
                <PremiumModal requiredType="hobby">
                    <div className="flex w-full flex-wrap items-end mb-6 justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                        <div className="flex items-center gap-2">
                            <Heading>Extra Pages</Heading>
                            <PremiumBadge type="hobby" />
                        </div>

                    </div>
                    <Fieldset>
                        <Legend>Manage Extra Pages</Legend>
                        <Text className="mb-4">Enable or disable additional pages for your bot's website.</Text>
                        <FieldGroup>
                            <Field>
                                <div className="flex items-center justify-between">
                                    <Label>Terms of Service</Label>
                                    <Switch
                                        checked={extra_pages.tos || false}
                                        onChange={() => this.handleToggle('tos')}
                                    />
                                </div>
                                <Text className="text-sm text-gray-500">Enable a Terms of Service page for your bot.</Text>
                                {extra_pages.tos && (
                                    <Text className="text-sm text-gray-400 mt-2">
                                        TOS page will be available at: <span className="font-semibold">/terms</span>
                                    </Text>
                                )}
                            </Field>
                            <Field>
                                <div className="flex items-center justify-between">
                                    <Label>Privacy Policy</Label>
                                    <Switch
                                        checked={extra_pages.privacy || false}
                                        onChange={() => this.handleToggle('privacy')}
                                    />
                                </div>
                                <Text className="text-sm text-gray-500">Enable a Privacy Policy page for your bot.</Text>
                                {extra_pages.privacy && (
                                    <Text className="text-sm text-gray-400 mt-2">
                                        Privacy Policy page will be available at: <span className="font-semibold">/privacy</span>
                                    </Text>
                                )}
                            </Field>
                        </FieldGroup>
                    </Fieldset>
                </PremiumModal>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot,
});

const mapDispatchToProps = {
    updateBotSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraPages);
