import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';

class ImageSettings extends Component {
    state = {
        layout: 1,
        src: '',
        alt: '',
        caption: '',
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        images: []
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleImageChange = (index, field, value) => {
        this.setState(prevState => {
            const updatedImages = [...prevState.images];
            updatedImages[index] = { ...updatedImages[index], [field]: value };
            return { images: updatedImages };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addImage = () => {
        this.setState(prevState => ({
            images: [...prevState.images, { src: '', alt: '', caption: '' }]
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeImage = (index) => {
        this.setState(prevState => ({
            images: prevState.images.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your image(s)</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Single Image</option>
                            <option value={2}>Grid Layout</option>
                            <option value={3}>Slide Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    {this.state.layout === 1 ? (
                        <>
                            <Field>
                                <Label>Image URL</Label>
                                <Description>Enter the URL of the image</Description>
                                <Input
                                    name="src"
                                    value={this.state.src}
                                    onChange={(e) => this.handleChange('src', e.target.value)}
                                    placeholder="https://example.com/image.jpg"
                                />
                            </Field>

                            <Field>
                                <Label>Alt Text</Label>
                                <Description>Enter alternative text for the image</Description>
                                <Input
                                    name="alt"
                                    value={this.state.alt}
                                    onChange={(e) => this.handleChange('alt', e.target.value)}
                                    placeholder="Description of the image"
                                />
                            </Field>

                            <Field>
                                <Label>Caption</Label>
                                <Description>Enter a caption for the image (optional)</Description>
                                <Input
                                    name="caption"
                                    value={this.state.caption}
                                    onChange={(e) => this.handleChange('caption', e.target.value)}
                                    placeholder="Image caption"
                                />
                            </Field>
                        </>
                    ) : (
                        <>
                            <Field>
                                <Label>Images</Label>
                                <Description>Add or edit images for the grid or slide layout</Description>
                                {this.state.images.map((image, index) => (
                                    <div key={index} className="mt-6 p-6 bg-gray-800 rounded-lg shadow-md">
                                        <Field>
                                            <Label>Image URL</Label>
                                            <Input
                                                value={image.src || ''}
                                                onChange={(e) => this.handleImageChange(index, 'src', e.target.value)}
                                                placeholder="https://example.com/image.jpg"
                                            />
                                        </Field>
                                        <Field className="mt-4">
                                            <Label>Alt Text</Label>
                                            <Input
                                                value={image.alt || ''}
                                                onChange={(e) => this.handleImageChange(index, 'alt', e.target.value)}
                                                placeholder="Description of the image"
                                            />
                                        </Field>
                                        <Field className="mt-4">
                                            <Label>Caption</Label>
                                            <Input
                                                value={image.caption || ''}
                                                onChange={(e) => this.handleImageChange(index, 'caption', e.target.value)}
                                                placeholder="Image caption"
                                            />
                                        </Field>
                                        <Button onClick={() => this.removeImage(index)} className="mt-6">Remove Image</Button>
                                    </div>
                                ))}
                                <Button onClick={this.addImage} className="mt-6">Add Image</Button>
                            </Field>
                        </>
                    )}
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default ImageSettings;
