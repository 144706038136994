import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';

class GapSettings extends Component {
    state = {
        height: 20 // Default gap height in pixels
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (e) => {
        const height = parseInt(e.target.value, 10);
        this.setState({ height }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Gap Height</Label>
                        <Description>Set the height of the gap in pixels</Description>
                        <Input
                            type="number"
                            name="height"
                            value={this.state.height}
                            onChange={this.handleChange}
                            min="0"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        />
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default GapSettings;
