import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Description, Field, FieldGroup, Fieldset, Label, Legend } from '../components/fieldset';
import { Input } from '../components/input';
import { Select } from '../components/select';
import { Text } from '../components/text';
import { Switch, SwitchField } from '../components/switch';
import { updateBotSettings } from '../actions';
import { Button } from '../components/button';
import { Accordion, AccordionItem } from '../components/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faBars, faHome } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

// List of Google Fonts (you can expand this list)
const googleFonts = [
    'Roboto',
    'Open Sans',
    'Lato',
    'Montserrat',
    'Raleway',
    'Poppins',
    'Oswald',
    'Source Sans Pro',
    'Slabo 27px',
    'Merriweather'
];

// List of 50 most popular Google Fonts
const popularGoogleFonts = [
    "Roboto", "Open Sans", "Lato", "Montserrat", "Roboto Condensed", "Source Sans Pro", "Oswald", "Poppins", "Roboto Mono", "Raleway",
    "Noto Sans", "Ubuntu", "Roboto Slab", "Merriweather", "PT Sans", "Playfair Display", "Lora", "Nunito", "PT Serif", "Titillium Web",
    "Rubik", "Noto Serif", "Fira Sans", "Work Sans", "Quicksand", "Nanum Gothic", "Oxygen", "Arimo", "Noto Sans KR", "Heebo",
    "Barlow", "Karla", "Josefin Sans", "Hind", "Libre Franklin", "Nunito Sans", "Libre Baskerville", "Crimson Text", "Mukta", "Inter",
    "Bitter", "Anton", "Dosis", "Cabin", "Fjalla One", "Arvo", "Noto Sans JP", "Lobster", "Yanone Kaffeesatz", "Inconsolata"
];

const defaultButtonStyles = {
    default: { padding: '0.5rem 1rem', borderRadius: '0.25rem' },
    compact: { padding: '0.25rem 0.5rem', borderRadius: '0.125rem' },
    large: { padding: '0.75rem 1.5rem', borderRadius: '0.5rem' },
    pill: { padding: '0.5rem 1rem', borderRadius: '9999px' },
};

export class HomeSettings extends Component {
    componentDidMount() {
        const { bot } = this.props.data;
        const currentSubscription = bot?.subscription?.type || 'free';
        const currentHomeType = bot?.home_settings?.homeType || 'simple';
        const canUseAdvancedBuilder = ['pro', 'hobby'].includes(currentSubscription);

        if (!canUseAdvancedBuilder && currentHomeType === 'advanced') {
            // User doesn't have required subscription but has advanced builder selected
            // Automatically switch to simple landing page
            const updatedHomeSettings = {
                ...bot.home_settings,
                homeType: 'simple'
            };
            this.props.updateBotSettings('home_settings', updatedHomeSettings);
        }
    }

    handleBackgroundTypeChange = (e) => {
        const home_settings = { ...this.props.data.bot.home_settings, backgroundType: e.target.value };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    handleColorChange = (key, value) => {
        const home_settings = { ...this.props.data.bot.home_settings, [key]: value };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    handleGradientAngleChange = (e) => {
        const home_settings = { ...this.props.data.bot.home_settings, gradientAngle: parseInt(e.target.value) };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    handleFontChange = (e) => {
        const home_settings = { ...this.props.data.bot.home_settings, font: e.target.value };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    handleButtonColorChange = (buttonType, colorType, color) => {
        const home_settings = {
            ...this.props.data.bot.home_settings,
            buttonColors: {
                ...this.props.data.bot.home_settings.buttonColors,
                [buttonType]: {
                    ...this.props.data.bot.home_settings.buttonColors?.[buttonType],
                    [colorType]: color
                }
            }
        };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    handleButtonGradientToggle = (buttonType, isGradient) => {
        const home_settings = {
            ...this.props.data.bot.home_settings,
            buttonColors: {
                ...this.props.data.bot.home_settings.buttonColors,
                [buttonType]: {
                    ...this.props.data.bot.home_settings.buttonColors?.[buttonType],
                    isGradient: isGradient
                }
            }
        };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    handleButtonStyleChange = (buttonType, style) => {
        const home_settings = {
            ...this.props.data.bot.home_settings,
            buttonStyles: {
                ...this.props.data.bot.home_settings.buttonStyles,
                [buttonType]: {
                    ...this.props.data.bot.home_settings.buttonStyles?.[buttonType],
                    ...style
                }
            }
        };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    handleButtonStylePresetChange = (buttonType, preset) => {
        this.handleButtonStyleChange(buttonType, defaultButtonStyles[preset]);
    };

    handleNavBarSettingsChange = (newSettings) => {
        const home_settings = { ...this.props.data.bot.home_settings, navBar: newSettings };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    handleHomeTypeChange = (e) => {
        const home_settings = { ...this.props.data.bot.home_settings, homeType: e.target.value };
        this.props.updateBotSettings('home_settings', home_settings);
    };

    renderColorPicker = (buttonType, colorType, color) => {
        return (
            <Field>
                <div className="flex items-center justify-between">
                    <div>
                        <Label className="text-white">{colorType.charAt(0).toUpperCase() + colorType.slice(1)} Color</Label>
                        <Description className="text-gray-400">Choose {colorType} color for {buttonType} buttons</Description>
                    </div>
                    <div className="flex items-center">
                        <label className="relative w-10 h-10 cursor-pointer mr-2">
                            <input
                                type="color"
                                onChange={(e) => this.handleButtonColorChange(buttonType, colorType, e.target.value)}
                                value={color || '#000000'}
                                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                            />
                            <div
                                className="absolute inset-0 rounded border-2 border-gray-500"
                                style={{ backgroundColor: color || '#000000' }}
                            ></div>
                        </label>
                        <span className="text-white">{color || '#000000'}</span>
                    </div>
                </div>
            </Field>
        );
    };

    renderButtonStyleSettings = (buttonType) => {
        const buttonStyle = this.props.data.bot.home_settings.buttonStyles?.[buttonType] || {};
        return (
            <>
                <Field>
                    <Label>Style Preset</Label>
                    <Select
                        value={Object.keys(defaultButtonStyles).find(key =>
                            JSON.stringify(defaultButtonStyles[key]) === JSON.stringify(buttonStyle)
                        ) || 'custom'}
                        onChange={(e) => this.handleButtonStylePresetChange(buttonType, e.target.value)}
                    >
                        <option value="default">Default</option>
                        <option value="compact">Compact</option>
                        <option value="large">Large</option>
                        <option value="pill">Pill</option>
                        <option value="custom">Custom</option>
                    </Select>
                </Field>
                <Field>
                    <Label>Padding</Label>
                    <Input
                        type="text"
                        value={buttonStyle.padding || ''}
                        onChange={(e) => this.handleButtonStyleChange(buttonType, { padding: e.target.value })}
                        placeholder="e.g., 0.5rem 1rem"
                    />
                </Field>
                <Field>
                    <Label>Border Radius</Label>
                    <Input
                        type="text"
                        value={buttonStyle.borderRadius || ''}
                        onChange={(e) => this.handleButtonStyleChange(buttonType, { borderRadius: e.target.value })}
                        placeholder="e.g., 0.25rem"
                    />
                </Field>
            </>
        );
    };

    renderNavBarSettings = () => {
        const { home_settings = {}, home_components = [] } = this.props.data.bot;
        const navBarSettings = home_settings.navBar || {};
        const navItems = navBarSettings.items || [];

        return (
            <Fieldset className="mt-10">
                <Legend className="text-white flex items-center text-xl font-semibold mb-4">
                    <FontAwesomeIcon icon={faBars} className="mr-2" />
                    NavBar Settings
                </Legend>
                <Text className="text-gray-400 mb-6">Customize the navigation bar of your bot's home page</Text>
                <FieldGroup>
                    <Field className="mb-6">
                        <Label className="text-white text-lg mb-2">NavBar Items</Label>
                        <Description className="text-gray-400 mb-4">Add, remove, or edit navigation items</Description>
                        <div className="space-y-4">
                            {navItems.map((item, index) => (
                                <div key={item.id} className="flex flex-wrap items-center gap-2 p-4 rounded-lg border border-gray-700">
                                    <Input
                                        value={item.label}
                                        onChange={(e) => {
                                            const newItems = [...navItems];
                                            newItems[index].label = e.target.value;
                                            this.handleNavBarSettingsChange({ ...navBarSettings, items: newItems });
                                        }}
                                        placeholder="Label"
                                        className="flex-grow"
                                    />
                                    <Select
                                        value={item.type}
                                        onChange={(e) => {
                                            const newItems = [...navItems];
                                            newItems[index].type = e.target.value;
                                            this.handleNavBarSettingsChange({ ...navBarSettings, items: newItems });
                                        }}
                                        className="w-full sm:w-auto"
                                    >
                                        <option value="section">Section</option>
                                        <option value="external">External Link</option>
                                        <option value="page">Page</option>
                                    </Select>
                                    {item.type === 'section' && (
                                        <Select
                                            value={item.sectionId}
                                            onChange={(e) => {
                                                const newItems = [...navItems];
                                                newItems[index].sectionId = e.target.value;
                                                this.handleNavBarSettingsChange({ ...navBarSettings, items: newItems });
                                            }}
                                            className="w-full sm:w-auto"
                                        >
                                            {home_components.map((component) => (
                                                <option key={component.id} value={component.id}>
                                                    {component.content}
                                                </option>
                                            ))}
                                        </Select>
                                    )}
                                    {item.type === 'external' && (
                                        <>
                                            <Input
                                                value={item.url}
                                                onChange={(e) => {
                                                    const newItems = [...navItems];
                                                    newItems[index].url = e.target.value;
                                                    this.handleNavBarSettingsChange({ ...navBarSettings, items: newItems });
                                                }}
                                                placeholder="URL"
                                                className="flex-grow"
                                            />
                                            <SwitchField className="ml-2">
                                                <Switch
                                                    checked={item.openInNewTab || false}
                                                    onChange={(checked) => {
                                                        const newItems = [...navItems];
                                                        newItems[index].openInNewTab = checked;
                                                        this.handleNavBarSettingsChange({ ...navBarSettings, items: newItems });
                                                    }}
                                                />
                                                <Label className="text-white ml-2">Open in new tab</Label>
                                            </SwitchField>
                                        </>
                                    )}
                                    {item.type === 'page' && (
                                        <Select
                                            value={item.pageId}
                                            onChange={(e) => {
                                                const newItems = [...navItems];
                                                newItems[index].pageId = e.target.value;
                                                this.handleNavBarSettingsChange({ ...navBarSettings, items: newItems });
                                            }}
                                            className="w-full sm:w-auto"
                                        >
                                            <option value="">Select a page</option>
                                            <option value="servers">Servers</option>
                                            {/* Add other pages here when implemented */}
                                        </Select>
                                    )}
                                    <Button
                                        onClick={() => {
                                            const newItems = navItems.filter((_, i) => i !== index);
                                            this.handleNavBarSettingsChange({ ...navBarSettings, items: newItems });
                                        }}
                                        className="bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded"
                                    >
                                        Remove
                                    </Button>
                                </div>
                            ))}
                        </div>
                        <Button
                            onClick={() => {
                                const newItems = [...navItems, { id: uuidv4(), label: '', type: 'section' }];
                                this.handleNavBarSettingsChange({ ...navBarSettings, items: newItems });
                            }}
                            className="mt-4 bg-black hover:bg-gray-800 text-white px-4 py-2 rounded"
                        >
                            Add NavBar Item
                        </Button>
                    </Field>
                    <Field className="mt-8">
                        <Label className="text-white text-lg mb-2">Nav Items Gap</Label>
                        <Description className="text-gray-400 mb-4">Set the gap between navigation items (in pixels)</Description>
                        <Input
                            type="number"
                            value={navBarSettings.itemsGap || 16}
                            onChange={(e) => this.handleNavBarSettingsChange({ ...navBarSettings, itemsGap: parseInt(e.target.value) })}
                            min="0"
                            max="100"
                        />
                    </Field>
                    <Field className="mt-8">
                        <Label className="text-white text-lg mb-2">CTA Button</Label>
                        <Description className="text-gray-400 mb-4">Customize the Call-to-Action button in the navbar</Description>
                        <div className="p-4 rounded-lg border border-gray-700">
                            <Input
                                value={navBarSettings.ctaText || `Invite ${this.props.data.bot.name}`}
                                onChange={(e) => this.handleNavBarSettingsChange({ ...navBarSettings, ctaText: e.target.value })}
                                placeholder="CTA Button Text"
                                className="mb-4"
                            />
                            <Select
                                value={navBarSettings.ctaStyle || 'primary'}
                                onChange={(e) => this.handleNavBarSettingsChange({ ...navBarSettings, ctaStyle: e.target.value })}
                                className="w-full"
                            >
                                <option value="primary">Primary</option>
                                <option value="secondary">Secondary</option>
                                <option value="success">Success</option>
                                <option value="danger">Danger</option>
                            </Select>
                        </div>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    };

    renderHomeTypeSelector = () => {
        const { home_settings = {}, subscription = {} } = this.props.data.bot;
        const currentSubscription = subscription.type || 'free';
        const canUseAdvancedBuilder = ['pro', 'hobby'].includes(currentSubscription);

        return (
            <Fieldset className="mb-10">
                <Legend className="text-white flex items-center text-xl font-semibold mb-4">
                    <FontAwesomeIcon icon={faHome} className="mr-2" />
                    Home Page Type
                </Legend>
                <Text className="text-gray-400 mb-6">Choose the type of home page for your bot</Text>
                <Field>
                    <Label className="text-white text-lg mb-2">Home Page Mode</Label>
                    <Select
                        value={home_settings.homeType || 'simple'}
                        onChange={this.handleHomeTypeChange}
                        className="w-full"
                    >
                        <option value="simple">Simple Landing Page</option>
                        <option value="advanced" disabled={!canUseAdvancedBuilder}>
                            Advanced Home (Builder) {!canUseAdvancedBuilder && '- Requires Pro or Hobby Plan'}
                        </option>
                        <option value="dashboard">Dashboard Only</option>
                    </Select>
                </Field>
                {!canUseAdvancedBuilder && (
                    <Text className="text-yellow-500 mt-2">
                        Upgrade to Pro or Hobby plan to use the Advanced Home Builder.
                    </Text>
                )}
            </Fieldset>
        );
    };

    renderSimpleLandingPageSettings = () => {
        const { home_settings = {} } = this.props.data.bot;
        return (
            <section>
                <Fieldset>
                    <Legend>Simple Landing Page Settings</Legend>
                    <Text>Customize the appearance of your simple landing page</Text>
                    <FieldGroup>
                        <Field>
                            <Label>Background Type</Label>
                            <Description>Choose between a solid color or a gradient background</Description>
                            <Select
                                value={home_settings.backgroundType || 'solid-color'}
                                onChange={this.handleBackgroundTypeChange}
                            >
                                <option value="solid-color">Solid Color</option>
                                <option value="linear-gradient">Linear Gradient</option>
                            </Select>
                        </Field>

                        {(home_settings.backgroundType || 'solid-color') === 'solid-color' ? (
                            <Field>
                                <div className="flex items-center justify-between">
                                    <div>
                                        <Label>Background Color</Label>
                                        <Description>Choose a solid color for the background</Description>
                                    </div>
                                    <label className="relative w-[50px] h-[50px] cursor-pointer">
                                        <input
                                            type="color"
                                            onChange={(e) => this.handleColorChange('backgroundColor', e.target.value)}
                                            value={home_settings.backgroundColor || '#ffffff'}
                                            className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                        />
                                        <div
                                            className="absolute inset-0 rounded border-2 border-gray-500"
                                            style={{ backgroundColor: home_settings.backgroundColor || '#ffffff' }}
                                        ></div>
                                    </label>
                                </div>
                            </Field>
                        ) : (
                            <>
                                <Field>
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <Label>Gradient Start Color</Label>
                                            <Description>Choose the starting color for the gradient</Description>
                                        </div>
                                        <label className="relative w-[50px] h-[50px] cursor-pointer">
                                            <input
                                                type="color"
                                                onChange={(e) => this.handleColorChange('gradientColors', [e.target.value, (home_settings.gradientColors || [])[1] || '#ffffff'])}
                                                value={(home_settings.gradientColors || [])[0] || '#ffffff'}
                                                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                            />
                                            <div
                                                className="absolute inset-0 rounded border-2 border-gray-500"
                                                style={{ backgroundColor: (home_settings.gradientColors || [])[0] || '#ffffff' }}
                                            ></div>
                                        </label>
                                    </div>
                                </Field>
                                <Field>
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <Label>Gradient End Color</Label>
                                            <Description>Choose the ending color for the gradient</Description>
                                        </div>
                                        <label className="relative w-[50px] h-[50px] cursor-pointer">
                                            <input
                                                type="color"
                                                onChange={(e) => this.handleColorChange('gradientColors', [(home_settings.gradientColors || [])[0] || '#ffffff', e.target.value])}
                                                value={(home_settings.gradientColors || [])[1] || '#ffffff'}
                                                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                            />
                                            <div
                                                className="absolute inset-0 rounded border-2 border-gray-500"
                                                style={{ backgroundColor: (home_settings.gradientColors || [])[1] || '#ffffff' }}
                                            ></div>
                                        </label>
                                    </div>
                                </Field>
                                <Field>
                                    <Label>Gradient Angle</Label>
                                    <Description>Set the angle of the gradient (0-360 degrees)</Description>
                                    <Input
                                        type="number"
                                        min="0"
                                        max="360"
                                        value={home_settings.gradientAngle || 45}
                                        onChange={this.handleGradientAngleChange}
                                    />
                                </Field>
                            </>
                        )}
                    </FieldGroup>
                </Fieldset>

                <hr className="my-8 border-t border-gray-700" />

                <Fieldset>
                    <Legend>Font Settings</Legend>
                    <Text>Choose the font for your simple landing page</Text>
                    <FieldGroup>
                        <Field>
                            <Label>Custom Font Family</Label>
                            <Description>Enter a custom Google Font name</Description>
                            <Input
                                value={home_settings.font || ''}
                                onChange={this.handleFontChange}
                                placeholder="Enter font name (e.g., Roboto)"
                            />
                        </Field>
                        <Field>
                            <Label>Popular Google Fonts</Label>
                            <Description>Or select from popular Google Fonts</Description>
                            <Select
                                value={home_settings.font || ''}
                                onChange={this.handleFontChange}
                            >
                                <option value="">Select a font</option>
                                {popularGoogleFonts.map(font => (
                                    <option key={font} value={font}>{font}</option>
                                ))}
                            </Select>
                        </Field>
                    </FieldGroup>
                </Fieldset>

                <hr className="my-8 border-t border-gray-700" />

                <Fieldset>
                    <Legend className="text-white flex items-center">
                        <FontAwesomeIcon icon={faPalette} className="mr-2" />
                        Button Settings
                    </Legend>
                    <Text className="text-gray-400 mb-4">Customize the appearance of buttons on your simple landing page</Text>
                    <Accordion>
                        {['primary', 'secondary'].map(buttonType => (
                            <AccordionItem
                                key={buttonType}
                                title={`${buttonType.charAt(0).toUpperCase() + buttonType.slice(1)} Button`}
                            >
                                <FieldGroup>
                                    <SwitchField>
                                        <Switch
                                            checked={home_settings.buttonColors?.[buttonType]?.isGradient || false}
                                            onChange={(checked) => this.handleButtonGradientToggle(buttonType, checked)}
                                        />
                                        <Label className="text-white ml-2">Use Gradient Background</Label>
                                    </SwitchField>
                                    {home_settings.buttonColors?.[buttonType]?.isGradient ? (
                                        <>
                                            {this.renderColorPicker(buttonType, 'gradientStart', home_settings.buttonColors?.[buttonType]?.gradientStart)}
                                            {this.renderColorPicker(buttonType, 'gradientEnd', home_settings.buttonColors?.[buttonType]?.gradientEnd)}
                                        </>
                                    ) : (
                                        this.renderColorPicker(buttonType, 'background', home_settings.buttonColors?.[buttonType]?.background)
                                    )}
                                    {this.renderColorPicker(buttonType, 'text', home_settings.buttonColors?.[buttonType]?.text)}
                                    {this.renderButtonStyleSettings(buttonType)}
                                </FieldGroup>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Fieldset>
            </section>
        );
    };

    render() {
        const { home_settings = {} } = this.props.data.bot;
        const homeType = home_settings.homeType || 'simple';

        return (
            <Dashboard header="Home Page Settings">
                <div className='flex gap-y-10 flex-col'>
                    {this.renderHomeTypeSelector()}

                    <hr className="border-t border-gray-700" />

                    {/* Always show Simple Landing Page settings */}
                    {this.renderSimpleLandingPageSettings()}

                    {homeType === 'advanced' && (
                        <section>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <Fieldset>
                                    <Legend>Background Settings</Legend>
                                    <Text>Customize the background of your bot's home page</Text>
                                    <FieldGroup>
                                        <Field>
                                            <Label>Background Type</Label>
                                            <Description>Choose between a solid color or a gradient background</Description>
                                            <Select
                                                value={home_settings.backgroundType || 'solid-color'}
                                                onChange={this.handleBackgroundTypeChange}
                                            >
                                                <option value="solid-color">Solid Color</option>
                                                <option value="linear-gradient">Linear Gradient</option>
                                            </Select>
                                        </Field>

                                        {(home_settings.backgroundType || 'solid-color') === 'solid-color' ? (
                                            <Field>
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <Label>Background Color</Label>
                                                        <Description>Choose a solid color for the background</Description>
                                                    </div>
                                                    <label className="relative w-[50px] h-[50px] cursor-pointer">
                                                        <input
                                                            type="color"
                                                            onChange={(e) => this.handleColorChange('backgroundColor', e.target.value)}
                                                            value={home_settings.backgroundColor || '#ffffff'}
                                                            className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                                        />
                                                        <div
                                                            className="absolute inset-0 rounded border-2 border-gray-500"
                                                            style={{ backgroundColor: home_settings.backgroundColor || '#ffffff' }}
                                                        ></div>
                                                    </label>
                                                </div>
                                            </Field>
                                        ) : (
                                            <>
                                                <Field>
                                                    <div className="flex items-center justify-between">
                                                        <div>
                                                            <Label>Gradient Start Color</Label>
                                                            <Description>Choose the starting color for the gradient</Description>
                                                        </div>
                                                        <label className="relative w-[50px] h-[50px] cursor-pointer">
                                                            <input
                                                                type="color"
                                                                onChange={(e) => this.handleColorChange('gradientColors', [e.target.value, (home_settings.gradientColors || [])[1] || '#ffffff'])}
                                                                value={(home_settings.gradientColors || [])[0] || '#ffffff'}
                                                                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                                            />
                                                            <div
                                                                className="absolute inset-0 rounded border-2 border-gray-500"
                                                                style={{ backgroundColor: (home_settings.gradientColors || [])[0] || '#ffffff' }}
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </Field>
                                                <Field>
                                                    <div className="flex items-center justify-between">
                                                        <div>
                                                            <Label>Gradient End Color</Label>
                                                            <Description>Choose the ending color for the gradient</Description>
                                                        </div>
                                                        <label className="relative w-[50px] h-[50px] cursor-pointer">
                                                            <input
                                                                type="color"
                                                                onChange={(e) => this.handleColorChange('gradientColors', [(home_settings.gradientColors || [])[0] || '#ffffff', e.target.value])}
                                                                value={(home_settings.gradientColors || [])[1] || '#ffffff'}
                                                                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                                            />
                                                            <div
                                                                className="absolute inset-0 rounded border-2 border-gray-500"
                                                                style={{ backgroundColor: (home_settings.gradientColors || [])[1] || '#ffffff' }}
                                                            ></div>
                                                        </label>
                                                    </div>
                                                </Field>
                                                <Field>
                                                    <Label>Gradient Angle</Label>
                                                    <Description>Set the angle of the gradient (0-360 degrees)</Description>
                                                    <Input
                                                        type="number"
                                                        min="0"
                                                        max="360"
                                                        value={home_settings.gradientAngle || 45}
                                                        onChange={this.handleGradientAngleChange}
                                                    />
                                                </Field>
                                            </>
                                        )}
                                    </FieldGroup>
                                </Fieldset>

                                <hr className="my-8 border-t border-gray-700" />

                                <Fieldset>
                                    <Legend>Font Settings</Legend>
                                    <Text>Choose the font for your bot's home page</Text>
                                    <FieldGroup>
                                        <Field>
                                            <Label>Custom Font Family</Label>
                                            <Description>Enter a custom Google Font name</Description>
                                            <Input
                                                value={home_settings.font || ''}
                                                onChange={this.handleFontChange}
                                                placeholder="Enter font name (e.g., Roboto)"
                                            />
                                        </Field>
                                        <Field>
                                            <Label>Popular Google Fonts</Label>
                                            <Description>Or select from popular Google Fonts</Description>
                                            <Select
                                                value={home_settings.font || ''}
                                                onChange={this.handleFontChange}
                                            >
                                                <option value="">Select a font</option>
                                                {popularGoogleFonts.map(font => (
                                                    <option key={font} value={font}>{font}</option>
                                                ))}
                                            </Select>
                                        </Field>
                                    </FieldGroup>
                                </Fieldset>

                                <hr className="my-8 border-t border-gray-700" />

                                <Fieldset>
                                    <Legend className="text-white flex items-center">
                                        <FontAwesomeIcon icon={faPalette} className="mr-2" />
                                        Button Settings
                                    </Legend>
                                    <Text className="text-gray-400 mb-4">Customize the appearance of buttons on your bot's home page</Text>
                                    <Accordion>
                                        {['primary', 'secondary', 'success', 'danger'].map(buttonType => (
                                            <AccordionItem
                                                key={buttonType}
                                                title={`${buttonType.charAt(0).toUpperCase() + buttonType.slice(1)} Button`}
                                            >
                                                <FieldGroup>
                                                    <SwitchField>
                                                        <Switch
                                                            checked={home_settings.buttonColors?.[buttonType]?.isGradient || false}
                                                            onChange={(checked) => this.handleButtonGradientToggle(buttonType, checked)}
                                                        />
                                                        <Label className="text-white ml-2">Use Gradient Background</Label>
                                                    </SwitchField>
                                                    {home_settings.buttonColors?.[buttonType]?.isGradient ? (
                                                        <>
                                                            {this.renderColorPicker(buttonType, 'gradientStart', home_settings.buttonColors?.[buttonType]?.gradientStart)}
                                                            {this.renderColorPicker(buttonType, 'gradientEnd', home_settings.buttonColors?.[buttonType]?.gradientEnd)}
                                                        </>
                                                    ) : (
                                                        this.renderColorPicker(buttonType, 'background', home_settings.buttonColors?.[buttonType]?.background)
                                                    )}
                                                    {this.renderColorPicker(buttonType, 'text', home_settings.buttonColors?.[buttonType]?.text)}
                                                    {this.renderButtonStyleSettings(buttonType)}
                                                </FieldGroup>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>

                                </Fieldset>
                            </form>
                        </section>
                    )}

                    {homeType === 'dashboard' && (
                        <Text className="text-gray-400">
                            Dashboard Only mode selected. Users will be directed straight to the dashboard.
                        </Text>
                    )}
                </div>

                {homeType === 'advanced' && (
                    <>
                        <hr className="my-8 border-t border-gray-700" />
                        {this.renderNavBarSettings()}
                    </>
                )}
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.data,
});

const mapDispatchToProps = {
    updateBotSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeSettings);
