import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Description, Field, FieldGroup, Fieldset, Label, Legend } from '../components/fieldset';
import { Input } from '../components/input';
import { Strong, Text, TextLink } from '../components/text';
import { Switch, SwitchField } from '../components/switch';
import { updateBotSettings } from '../actions';
import { Button } from '../components/button';
import server from '../utils/server';
import { Heading } from '../components/heading';
import PremiumBadge from '../components/PremiumBadge';
import PremiumModal from '../components/PremiumModal';

export class AuthSettings extends Component {
    resetAuthSecret = async () => {
        try {
            const response = await server.post('/dashboard/reset-auth-secret', {
                botId: this.props.data.bot.id
            });
            if (response.data.success) {
                alert('Auth secret has been reset successfully.');
            } else {
                throw new Error(response.data.error || 'Failed to reset auth secret');
            }
        } catch (error) {
            console.error('Error resetting auth secret:', error);
            alert('Failed to reset auth secret. Please try again.');
        }
    };

    render() {
        const { bot } = this.props.data;
        const authSettings = bot.auth_settings || {};

        return (
            <Dashboard>
                <PremiumModal requiredType="basic">
                    <div className="flex w-full flex-wrap items-end mb-6 justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                        <div className="flex items-center gap-2">
                            <Heading>Authentication Settings</Heading>
                            <PremiumBadge type="basic" />
                        </div>

                    </div>
                    <div className='flex gap-y-10 flex-col'>
                        <section>
                            <Fieldset>
                                <Legend>Custom Discord OAuth2 Settings</Legend>
                                <Text>
                                    Enable custom authentication to use your own Discord application for user login instead of the default BotPanel one.
                                    This allows you to have full control over the authentication process and branding for your bot's dashboard.
                                </Text>
                                <FieldGroup>
                                    <Field>
                                        <SwitchField>
                                            <Label>Use Custom Authentication</Label>
                                            <Description>Enable to use your own Discord OAuth2 credentials</Description>
                                            <Switch
                                                color="sky"
                                                checked={authSettings.use_custom_auth || false}
                                                onChange={(value) => {
                                                    const newAuthSettings = { ...authSettings, use_custom_auth: value };
                                                    this.props.updateBotSettings('auth_settings', newAuthSettings);
                                                }}
                                            />
                                        </SwitchField>
                                    </Field>

                                    {authSettings.use_custom_auth && (
                                        <>
                                            <Field>
                                                <Label>Client ID</Label>
                                                <Description>Your Discord application's Client ID</Description>
                                                <Input
                                                    onChange={(e) => {
                                                        const newAuthSettings = { ...authSettings, client_id: e.target.value };
                                                        this.props.updateBotSettings('auth_settings', newAuthSettings);
                                                    }}
                                                    value={authSettings.client_id || ''}
                                                />
                                            </Field>

                                            <Field>
                                                <Label>Client Secret</Label>
                                                <Description>Your Discord application's Client Secret</Description>
                                                <Input
                                                    type="password"
                                                    onChange={(e) => {
                                                        const newAuthSettings = { ...authSettings, client_secret: e.target.value };
                                                        this.props.updateBotSettings('auth_settings', newAuthSettings);
                                                    }}
                                                    value={authSettings.client_secret || ''}
                                                />
                                            </Field>
                                        </>
                                    )}
                                </FieldGroup>
                            </Fieldset>
                        </section>

                        {authSettings.use_custom_auth && (
                            <>
                                <hr className="border-t border-gray-300 my-6" />

                                <section>
                                    <Fieldset>
                                        <Legend>Authentication Information</Legend>
                                        <Text>Important details about setting up your Discord application</Text>
                                        <FieldGroup>
                                            <Field>
                                                <Label>Discord Developer Portal</Label>
                                                <Description>
                                                    Create and manage your Discord application at the
                                                    <TextLink href="https://discord.com/developers/applications" target="_blank" rel="noopener noreferrer"> Discord Developer Portal</TextLink>.
                                                </Description>
                                            </Field>
                                            <Field>
                                                <Label>OAuth2 Credentials</Label>
                                                <Description>
                                                    To find your Client ID and Client Secret:
                                                    <ol className="list-decimal list-inside mt-2 ml-4">
                                                        <li>Go to the <TextLink href="https://discord.com/developers/applications" target="_blank" rel="noopener noreferrer">Discord Developer Portal</TextLink></li>
                                                        <li>Select your application</li>
                                                        <li>Click on "OAuth2" in the left sidebar</li>
                                                        <li>Under "Client information", you'll find your Client ID</li>
                                                        <li>Click "Reset Secret" to generate a new Client Secret if needed</li>
                                                        <li>Copy these values and paste them in the fields above</li>
                                                        <li>Never share your Client Secret publicly</li>
                                                    </ol>
                                                </Description>
                                            </Field>
                                            <Field>
                                                <Label>Redirect URI</Label>
                                                <Description>
                                                    Add the following Redirect URI to your Discord application's OAuth2 settings:
                                                    <Strong> https://api.botpanel.gg/api/auth/botpanel/callback</Strong>
                                                </Description>
                                                <Text className="mt-2">
                                                    To add this Redirect URI:
                                                    <ol className="list-decimal list-inside mt-2 ml-4">
                                                        <li>Go to the <TextLink href="https://discord.com/developers/applications" target="_blank" rel="noopener noreferrer">Discord Developer Portal</TextLink></li>
                                                        <li>Select your application</li>
                                                        <li>Click on "OAuth2" in the left sidebar</li>
                                                        <li>Scroll down to "Redirects"</li>
                                                        <li>Click "Add Redirect" and enter the URL above</li>
                                                        <li>Save your changes</li>
                                                    </ol>
                                                </Text>
                                            </Field>
                                        </FieldGroup>
                                    </Fieldset>
                                </section>

                                <hr className="border-t border-gray-300 my-6" />

                                <section>
                                    <Fieldset>
                                        <Legend>Auth Secret</Legend>
                                        <Text>Reset the authentication secret for this bot</Text>
                                        <FieldGroup>
                                            <Field>
                                                <Label>Reset Auth Secret</Label>
                                                <Description>
                                                    This will generate a new authentication secret for your bot.
                                                    Any existing tokens will be invalidated.
                                                    All users will have to re-authenticate.
                                                </Description>
                                                <Button
                                                    onClick={this.resetAuthSecret}
                                                    className="mt-2"
                                                    variant="danger"
                                                >
                                                    Reset Auth Secret
                                                </Button>
                                            </Field>
                                        </FieldGroup>
                                    </Fieldset>
                                </section>
                            </>
                        )}
                    </div>
                </PremiumModal>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.data,
});

const mapDispatchToProps = {
    updateBotSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthSettings);
