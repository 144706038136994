import React from 'react';
import { Field, FieldGroup, Label, Description } from './fieldset';
import { Input } from './input';
import { Select } from './select';
import TextSizeSelect from '../pages/home_settings/TextSizeSelect';
import { Accordion, AccordionItem } from './accordion';
import { Switch } from './switch';

const TitleDescriptionSettings = ({ settings, onChange }) => {
    const handleChange = (field, value) => {
        // If value is an event object, extract the actual value
        const newValue = value && value.target ? value.target.value : value;
        onChange(field, newValue);
    };

    const renderColorPicker = (label, field) => (
        <div className="flex items-center justify-between">
            <Label>{label} Color</Label>
            <div className="flex items-center">
                <label className="relative w-10 h-10 cursor-pointer mr-2">
                    <input
                        type="color"
                        value={settings[`${field}Color`] || '#000000'}
                        onChange={(e) => handleChange(`${field}Color`, e.target.value)}
                        className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                    />
                    <div
                        className="absolute inset-0 rounded border-2 border-gray-500"
                        style={{ backgroundColor: settings[`${field}Color`] || '#000000' }}
                    ></div>
                </label>
                <span className="text-white">{settings[`${field}Color`] || '#000000'}</span>
            </div>
        </div>
    );

    const handleFullWidthBackgroundChange = (checked) => {
        handleChange('fullWidthBackground', checked);
        if (checked && !settings.backgroundType) {
            handleChange('backgroundType', 'solid');
        }
    };

    return (
        <Accordion>
            <AccordionItem title="Title and Background Settings">
                <FieldGroup>
                    <Field>
                        <Label>Pretitle</Label>
                        <Description>A short text displayed above the main title</Description>
                        <Input
                            name="pretitle"
                            value={settings.pretitle || ''}
                            onChange={(e) => handleChange('pretitle', e.target.value)}
                        />
                        <TextSizeSelect
                            value={settings.pretitleSize || 'text-base'}
                            onChange={(value) => handleChange('pretitleSize', value)}
                        />
                        {renderColorPicker('Pretitle', 'pretitle')}
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Title</Label>
                        <Description>The main title for this section</Description>
                        <Input
                            name="title"
                            value={settings.title || ''}
                            onChange={(e) => handleChange('title', e.target.value)}
                        />
                        <TextSizeSelect
                            value={settings.titleSize || 'text-2xl'}
                            onChange={(value) => handleChange('titleSize', value)}
                        />
                        {renderColorPicker('Title', 'title')}
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Description</Label>
                        <Description>A brief description for this section</Description>
                        <Input
                            name="description"
                            value={settings.description || ''}
                            onChange={(e) => handleChange('description', e.target.value)}
                        />
                        <TextSizeSelect
                            value={settings.descriptionSize || 'text-base'}
                            onChange={(value) => handleChange('descriptionSize', value)}
                        />
                        {renderColorPicker('Description', 'description')}
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Text Justification</Label>
                        <Description>Choose the alignment for your text</Description>
                        <Select
                            name="textJustification"
                            value={settings.textJustification || 'left'}
                            onChange={(e) => handleChange('textJustification', e.target.value)}
                        >
                            <option value="left">Left</option>
                            <option value="center">Center</option>
                            <option value="right">Right</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Vertical Padding</Label>
                        <Description>Set the vertical padding for this component (e.g., 4rem, 64px). Leave empty for default padding.</Description>
                        <Input
                            type="text"
                            value={settings.verticalPadding || ''}
                            onChange={(e) => handleChange('verticalPadding', e.target.value)}
                            placeholder="e.g., 4rem"
                        />
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Full Width Background</Label>
                        <Description>Enable full width background for this component</Description>
                        <Switch
                            checked={settings.fullWidthBackground || false}
                            onChange={handleFullWidthBackgroundChange}
                        />
                    </Field>

                    {settings.fullWidthBackground && (
                        <>
                            <Field>
                                <Label>Background Type</Label>
                                <Select
                                    value={settings.backgroundType || 'solid'}
                                    onChange={(e) => handleChange('backgroundType', e.target.value)}
                                >
                                    <option value="solid">Solid Color</option>
                                    <option value="gradient">Gradient</option>
                                </Select>
                            </Field>

                            {(settings.backgroundType === 'solid' || !settings.backgroundType) ? (
                                <Field>
                                    {renderColorPicker('Background', 'backgroundColor')}
                                </Field>
                            ) : (
                                <>
                                    <Field>
                                        {renderColorPicker('Gradient Start', 'gradientStartColor')}
                                    </Field>
                                    <Field>
                                        {renderColorPicker('Gradient End', 'gradientEndColor')}
                                    </Field>
                                    <Field>
                                        <Label>Gradient Angle</Label>
                                        <Input
                                            type="number"
                                            value={settings.gradientAngle || 45}
                                            onChange={(e) => handleChange('gradientAngle', parseInt(e.target.value))}
                                            min="0"
                                            max="360"
                                        />
                                    </Field>
                                </>
                            )}
                        </>
                    )}
                </FieldGroup>
            </AccordionItem>
        </Accordion>
    );
};

export default TitleDescriptionSettings;
