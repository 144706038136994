import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect } from 'react-redux';
import moment from 'moment';
import TestDataWatermark from './TestDataWatermark';

const StatCard = ({ title, value, change, colorClass, data, showChart = true, serverIcon = null }) => {
    const sparklineOptions = {
        chart: {
            type: 'line',
            sparkline: {
                enabled: true
            },
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return '';
                    }
                }
            },
            marker: {
                show: false
            }
        },
        colors: ['#ffffff'],
    };

    return (
        <div className={`${colorClass} p-4 rounded-lg shadow-md relative overflow-hidden`}>
            <div className="relative z-10">
                <h3 className="text-gray-100 text-sm font-semibold uppercase">{title}</h3>
                <div className="flex items-center mt-2">
                    {serverIcon && (
                        <img src={serverIcon} alt="Server Icon" className="w-6 h-6 mr-2 rounded-full" />
                    )}
                    <p className="text-white text-3xl font-bold">{value}</p>
                    {change !== null && !isNaN(change) && (
                        <span className={`ml-2 text-sm font-medium ${change >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                            {change >= 0 ? '↑' : '↓'} {Math.abs(change).toFixed(1)}%
                        </span>
                    )}
                </div>
            </div>
            {showChart && (
                <div className="absolute bottom-0 right-0 w-2/3 h-16 opacity-50">
                    <ReactApexChart options={sparklineOptions} series={[{ data: data }]} type="line" height={60} />
                </div>
            )}
        </div>
    );
};

const formatCurrency = (value) => {
    if (typeof value === 'number') {
        return `$${value.toFixed(2)}`;
    }
    return value; // Already formatted
};

const RevenueStatCard = ({ title, expected, received, lastPeriod, updatedTime }) => {
    return (
        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
            <h3 className="text-gray-300 text-sm font-semibold uppercase mb-2">{title}</h3>
            <div className="space-y-1">
                <p className="text-gray-400 text-sm flex justify-between">
                    <span>Expected</span>
                    <span className="text-white font-semibold">{formatCurrency(expected)}</span>
                </p>
                <p className="text-gray-400 text-sm flex justify-between">
                    <span>Received</span>
                    <span className="text-green-400 font-semibold">{formatCurrency(received)}</span>
                </p>
                <p className="text-gray-400 text-sm flex justify-between">
                    <span>{title.includes('Today') ? 'Yesterday' : 'Last Month'}</span>
                    <span className="text-blue-400 font-semibold">{formatCurrency(lastPeriod)}</span>
                </p>
            </div>
            <p className="text-gray-500 text-xs mt-2">Updated {updatedTime}</p>
        </div>
    );
};

const SignupStatCard = ({ title, current, lastPeriod, updatedTime }) => {
    return (
        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
            <h3 className="text-gray-300 text-sm font-semibold uppercase mb-2">{title}</h3>
            <div className="space-y-1">
                <p className="text-gray-400 text-sm flex justify-between">
                    <span>Current</span>
                    <span className="text-green-400 font-semibold">{current}</span>
                </p>
                <p className="text-gray-400 text-sm flex justify-between">
                    <span>{title.includes('Today') ? 'Yesterday' : 'Last Month'}</span>
                    <span className="text-white font-semibold">{lastPeriod}</span>
                </p>
            </div>
            <p className="text-gray-500 text-xs mt-2">Updated {updatedTime}</p>
        </div>
    );
};

const formatNumber = (num, isCurrency = false) => {
    if (isCurrency) {
        return `$${num.toFixed(2)}`;
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'k';
    }
    return num.toString();
};

const OverviewStatsSection = ({ analytics, servers, modules, placeholder }) => {
    // Sort analytics by date in ascending order (oldest first)
    const sortedAnalytics = [...analytics].sort((a, b) =>
        moment(a.date.$date).valueOf() - moment(b.date.$date).valueOf()
    );

    const todayData = sortedAnalytics[sortedAnalytics.length - 1] || {};
    const yesterdayData = sortedAnalytics[sortedAnalytics.length - 2] || {};

    const calculateChange = (todayValue, yesterdayValue) => {
        if (yesterdayValue === undefined) return null; // No change if we only have today's data
        if (yesterdayValue === 0) return todayValue > 0 ? 100 : 0;
        return ((todayValue - yesterdayValue) / yesterdayValue) * 100;
    };

    const getServerInfo = (serverId) => {
        const server = servers.find(s => s.id === serverId);
        return server ? { name: server.name, icon: server.icon } : { name: 'Unknown Server', icon: null };
    };

    const sumObjectValues = (obj) => Object.values(obj || {}).reduce((sum, count) => sum + count, 0);

    const sumNestedObjectValues = (obj) => {
        if (!obj) return 0;
        return Object.values(obj).reduce((sum, value) => {
            if (typeof value === 'object') {
                return sum + sumNestedObjectValues(value);
            }
            return sum + (typeof value === 'number' ? value : 0);
        }, 0);
    };

    const commandIdToName = {};
    modules.forEach(module => {
        module.commands.forEach(command => {
            commandIdToName[command.int_id] = command.name;
        });
    });

    // Updated dummy data for placeholder
    const dummyData = {
        currentServerCount: 17823,
        serverCountChange: 12.5,
        serverSparkline: [17234, 17456, 17678, 17800, 17823].map(formatNumber),

        currentMemberCount: 23456,
        memberCountChange: 8.7,
        memberSparkline: [22000, 22500, 23000, 23300, 23456].map(formatNumber),

        commandsUsedToday: 9876,
        commandsUsedChange: 14.3,
        commandSparkline: [9000, 9200, 9400, 9600, 9876].map(formatNumber),

        viewsToday: 54321,
        viewsChange: 19.8,
        viewSparkline: [50000, 51000, 52000, 53000, 54321].map(formatNumber),

        messagesReceivedToday: 67890,
        messagesReceivedChange: 22.1,
        messagesReceivedSparkline: [65000, 66000, 67000, 67500, 67890].map(formatNumber),

        mostActiveServer: {
            name: 'BotGhost',
            icon: 'https://dashboard.botghost.com/images/logo-red.png'
        },
        mostActiveServerSparkline: [200, 250, 300, 350, 400].map(formatNumber),

        newServersToday: 123,
        newServersChange: 3.4,
        newServersSparkline: [100, 110, 115, 120, 123].map(formatNumber),

        mostPopularCommand: 'help',
        mostPopularCommandSparkline: [60, 70, 80, 90, 100].map(formatNumber),
    };

    const dummyRevenueData = {
        revenueThisMonth: {
            expected: 123456.78,
            received: 98765.43,
            lastMonth: 112233.44
        },
        todayRevenue: {
            expected: 5678.90,
            received: 4321.09,
            yesterday: 5000.00
        },
        signupsThisMonth: {
            current: 3456,
            lastMonth: 3000
        },
        signupsToday: {
            current: 234,
            yesterday: 200
        }
    };

    // Use dummy data if placeholder is true
    const topStatsData = placeholder ? {
        ...dummyData,
        currentServerCount: formatNumber(dummyData.currentServerCount),
        currentMemberCount: formatNumber(dummyData.currentMemberCount),
        commandsUsedToday: formatNumber(dummyData.commandsUsedToday),
        viewsToday: formatNumber(dummyData.viewsToday),
        messagesReceivedToday: formatNumber(dummyData.messagesReceivedToday),
        newServersToday: formatNumber(dummyData.newServersToday),
    } : {
        currentServerCount: formatNumber(todayData.server?.total || 0),
        serverCountChange: calculateChange(todayData.server?.total || 0, yesterdayData.server?.total),
        serverSparkline: sortedAnalytics.slice(-5).map(item => formatNumber(item.server?.total || 0)),

        currentMemberCount: formatNumber(sumObjectValues(todayData.member)),
        memberCountChange: calculateChange(sumObjectValues(todayData.member), sumObjectValues(yesterdayData.member)),
        memberSparkline: sortedAnalytics.slice(-5).map(item => formatNumber(sumObjectValues(item.member))),

        commandsUsedToday: formatNumber(sumNestedObjectValues(todayData.commands)),
        commandsUsedChange: calculateChange(
            sumNestedObjectValues(todayData.commands),
            sumNestedObjectValues(yesterdayData.commands)
        ),
        commandSparkline: sortedAnalytics.slice(-5).map(item => formatNumber(sumNestedObjectValues(item.commands))),

        viewsToday: formatNumber(sumObjectValues(todayData.views)),
        viewsChange: calculateChange(sumObjectValues(todayData.views), sumObjectValues(yesterdayData.views)),
        viewSparkline: sortedAnalytics.slice(-5).map(item => formatNumber(sumObjectValues(item.views))),

        messagesReceivedToday: formatNumber(sumObjectValues(todayData.messages)),
        messagesReceivedChange: calculateChange(sumObjectValues(todayData.messages), sumObjectValues(yesterdayData.messages)),
        messagesReceivedSparkline: sortedAnalytics.slice(-5).map(item => formatNumber(sumObjectValues(item.messages))),

        mostActiveServer: (() => {
            const { serverId } = Object.entries(todayData.messages || {}).reduce((max, [serverId, count]) =>
                count > max.count ? { serverId, count } : max, { serverId: null, count: 0 }
            );
            return getServerInfo(serverId);
        })(),
        mostActiveServerSparkline: sortedAnalytics.slice(-5).map(item =>
            formatNumber(Math.max(...Object.values(item.messages || {}), 0))
        ),

        newServersToday: formatNumber(todayData.server?.adds || 0),
        newServersChange: calculateChange(todayData.server?.adds || 0, yesterdayData.server?.adds),
        newServersSparkline: sortedAnalytics.slice(-5).map(item => formatNumber(item.server?.adds || 0)),

        mostPopularCommand: (() => {
            let maxCount = 0;
            let maxCmdId = "N/A";
            Object.entries(todayData.commands || {}).forEach(([serverId, commands]) => {
                Object.entries(commands).forEach(([cmdId, count]) => {
                    if (count > maxCount) {
                        maxCount = count;
                        maxCmdId = cmdId;
                    }
                });
            });
            return commandIdToName[maxCmdId] || maxCmdId;
        })(),
        mostPopularCommandSparkline: sortedAnalytics.slice(-5).map(item =>
            formatNumber(Math.max(...Object.values(item.commands || {}).flatMap(cmds => Object.values(cmds)), 0))
        ),
    };

    const revenueStatsData = placeholder ? {
        revenueThisMonth: {
            expected: formatNumber(dummyRevenueData.revenueThisMonth.expected, true),
            received: formatNumber(dummyRevenueData.revenueThisMonth.received, true),
            lastMonth: formatNumber(dummyRevenueData.revenueThisMonth.lastMonth, true)
        },
        todayRevenue: {
            expected: formatNumber(dummyRevenueData.todayRevenue.expected, true),
            received: formatNumber(dummyRevenueData.todayRevenue.received, true),
            yesterday: formatNumber(dummyRevenueData.todayRevenue.yesterday, true)
        },
        signupsThisMonth: {
            current: formatNumber(dummyRevenueData.signupsThisMonth.current),
            lastMonth: formatNumber(dummyRevenueData.signupsThisMonth.lastMonth)
        },
        signupsToday: {
            current: formatNumber(dummyRevenueData.signupsToday.current),
            yesterday: formatNumber(dummyRevenueData.signupsToday.yesterday)
        }
    } : {
        revenueThisMonth: {
            expected: formatNumber(0, true),
            received: formatNumber(0, true),
            lastMonth: formatNumber(0, true)
        },
        todayRevenue: {
            expected: formatNumber(0, true),
            received: formatNumber(0, true),
            yesterday: formatNumber(0, true)
        },
        signupsThisMonth: {
            current: 0,
            lastMonth: 0
        },
        signupsToday: {
            current: 0,
            yesterday: 0
        }
    };

    return (
        <>
            <section className="mb-8">
                {/* Test Data Watermark */}


                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    <StatCard
                        title="Current Server Count"
                        value={topStatsData.currentServerCount}
                        change={topStatsData.serverCountChange}
                        colorClass="bg-gradient-to-br from-blue-500 to-indigo-600"
                        data={topStatsData.serverSparkline}
                    />
                    <StatCard
                        title="Current Member Count"
                        value={topStatsData.currentMemberCount}
                        change={topStatsData.memberCountChange}
                        colorClass="bg-gradient-to-br from-purple-500 to-pink-500"
                        data={topStatsData.memberSparkline}
                    />
                    <StatCard
                        title="Commands Used Today"
                        value={topStatsData.commandsUsedToday}
                        change={topStatsData.commandsUsedChange}
                        colorClass="bg-gradient-to-br from-emerald-500 to-teal-600"
                        data={topStatsData.commandSparkline}
                    />
                    <StatCard
                        title="Views Today"
                        value={topStatsData.viewsToday}
                        change={topStatsData.viewsChange}
                        colorClass="bg-gradient-to-br from-orange-400 to-pink-500"
                        data={topStatsData.viewSparkline}
                    />
                </div>
            </section>
            <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
                <StatCard
                    title="Messages Received Today"
                    value={topStatsData.messagesReceivedToday}
                    change={topStatsData.messagesReceivedChange}
                    colorClass="bg-gradient-to-br from-cyan-500 to-blue-500"
                    data={topStatsData.messagesReceivedSparkline}
                />
                <StatCard
                    title="Most Active Server"
                    value={topStatsData.mostActiveServer.name || "N/A"}
                    change={null}
                    colorClass="bg-gradient-to-br from-violet-500 to-purple-600"
                    showChart={false}
                    serverIcon={topStatsData.mostActiveServer.icon}
                />
                <StatCard
                    title="New Servers Today"
                    value={topStatsData.newServersToday}
                    change={topStatsData.newServersChange}
                    colorClass="bg-gradient-to-br from-amber-400 to-orange-500"
                    data={topStatsData.newServersSparkline}
                />
                <StatCard
                    title="Most Popular Command"
                    value={"/" + topStatsData.mostPopularCommand || "N/A"}
                    change={null}
                    colorClass="bg-gradient-to-br from-rose-500 to-red-600"
                    showChart={false}
                />
            </section>

            <section className="mb-8">
                <h2 className="text-xl font-bold text-white mb-4">Revenue and Signups</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    <RevenueStatCard
                        title="Revenue This Month"
                        expected={revenueStatsData.revenueThisMonth.expected}
                        received={revenueStatsData.revenueThisMonth.received}
                        lastPeriod={revenueStatsData.revenueThisMonth.lastMonth}
                        updatedTime="1 hour ago"
                    />
                    <RevenueStatCard
                        title="Today's Revenue"
                        expected={revenueStatsData.todayRevenue.expected}
                        received={revenueStatsData.todayRevenue.received}
                        lastPeriod={revenueStatsData.todayRevenue.yesterday}
                        updatedTime="1 hour ago"
                    />
                    <SignupStatCard
                        title="Signups This Month"
                        current={revenueStatsData.signupsThisMonth.current}
                        lastPeriod={revenueStatsData.signupsThisMonth.lastMonth}
                        updatedTime="1 hour ago"
                    />
                    <SignupStatCard
                        title="Signups Today"
                        current={revenueStatsData.signupsToday.current}
                        lastPeriod={revenueStatsData.signupsToday.yesterday}
                        updatedTime="1 hour ago"
                    />
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state) => ({
    analytics: state.data.analytics,
    servers: state.data.servers,
    modules: state.data.modules,
    bot: state.data.bot
});

export default connect(mapStateToProps)(OverviewStatsSection);
