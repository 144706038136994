import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import { Textarea } from '../../components/textarea';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';

class FAQsSettings extends Component {
    state = {
        layout: 1,
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        backgroundColor: '#1F2937',
        textColor: '#FFFFFF',
        activeBackgroundColor: '#374151',
        activeTextColor: '#FFFFFF',
        questionFontSize: '16px',
        answerFontSize: '14px',
        faqBackgroundColor: '#F3F4F6',
        faqTextColor: '#111827',
        borderRadius: '8px',
        faqs: []
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleFAQChange = (index, field, value) => {
        this.setState(prevState => {
            const updatedFAQs = [...prevState.faqs];
            updatedFAQs[index] = { ...updatedFAQs[index], [field]: value };
            return { faqs: updatedFAQs };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addFAQ = () => {
        this.setState(prevState => ({
            faqs: [...prevState.faqs, { question: '', answer: '' }]
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeFAQ = (index) => {
        this.setState(prevState => ({
            faqs: prevState.faqs.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    renderColorPicker = (label, description, field, value) => {
        return (
            <Field>
                <div className="flex items-center justify-between">
                    <div>
                        <Label className="text-white">{label}</Label>
                        <Description className="text-gray-400">{description}</Description>
                    </div>
                    <div className="flex items-center">
                        <label className="relative w-10 h-10 cursor-pointer mr-2">
                            <input
                                type="color"
                                onChange={(e) => this.handleChange(field, e.target.value)}
                                value={value || '#000000'}
                                className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                            />
                            <div
                                className="absolute inset-0 rounded border-2 border-gray-500"
                                style={{ backgroundColor: value || '#000000' }}
                            ></div>
                        </label>
                        <span className="text-white">{value || '#000000'}</span>
                    </div>
                </div>
            </Field>
        );
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your FAQs section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Accordion Layout</option>
                            <option value={2}>Two Column Layout</option>
                            <option value={3}>Minimalist Layout</option>
                            <option value={4}>Grid Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    {(this.state.layout === 1 || this.state.layout === 4) && (
                        <>
                            <Field>
                                <Label className="text-lg font-semibold">FAQ Item Settings</Label>
                                <Description>Customize the appearance of the FAQ items</Description>
                            </Field>
                            {this.renderColorPicker(
                                "FAQ Background Color",
                                "Choose the background color for FAQ items",
                                "faqBackgroundColor",
                                this.state.faqBackgroundColor
                            )}
                            {this.renderColorPicker(
                                "FAQ Text Color",
                                "Choose the text color for FAQ items",
                                "faqTextColor",
                                this.state.faqTextColor
                            )}
                            <Field>
                                <Label>Question Font Size</Label>
                                <Input
                                    type="text"
                                    value={this.state.questionFontSize}
                                    onChange={(e) => this.handleChange('questionFontSize', e.target.value)}
                                    placeholder="e.g., 16px"
                                />
                            </Field>
                            <Field>
                                <Label>Answer Font Size</Label>
                                <Input
                                    type="text"
                                    value={this.state.answerFontSize}
                                    onChange={(e) => this.handleChange('answerFontSize', e.target.value)}
                                    placeholder="e.g., 14px"
                                />
                            </Field>
                            <Field>
                                <Label>Border Radius</Label>
                                <Input
                                    type="text"
                                    value={this.state.borderRadius}
                                    onChange={(e) => this.handleChange('borderRadius', e.target.value)}
                                    placeholder="e.g., 8px"
                                />
                            </Field>
                            <hr className="my-6 border-t border-gray-700" />
                        </>
                    )}

                    <Field>
                        <Label>FAQs</Label>
                        <Description>Add or edit individual FAQs</Description>
                        {this.state.faqs && this.state.faqs.map((faq, index) => (
                            <div key={index} className="mt-6 p-6 bg-gray-800 rounded-lg shadow-md">
                                <Field>
                                    <Label>Question</Label>
                                    <Input
                                        value={faq.question || ''}
                                        onChange={(e) => this.handleFAQChange(index, 'question', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-4">
                                    <Label>Answer</Label>
                                    <Textarea
                                        value={faq.answer || ''}
                                        onChange={(e) => this.handleFAQChange(index, 'answer', e.target.value)}
                                        rows={4}
                                    />
                                </Field>
                                <Button onClick={() => this.removeFAQ(index)} className="mt-6">Remove FAQ</Button>
                            </div>
                        ))}
                        <Button onClick={this.addFAQ} className="mt-6">Add FAQ</Button>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default FAQsSettings;
