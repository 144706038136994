import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Heading, Subheading } from '../components/heading';
import { Button } from '../components/button';
import { ArrowPathIcon, PlusIcon } from '@heroicons/react/16/solid';
import { Badge } from '../components/badge';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/dialog';
import { Field, Label, ErrorMessage } from '../components/fieldset';
import { Input } from '../components/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table';
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../components/alert';
import server from '../utils/server';
import { Text, TextLink } from '../components/text';
import { Divider } from '../components/divider';
import { updateBotSettings } from '../actions/index';
import toast from 'react-hot-toast'; // Add this import
import PremiumBadge from '../components/PremiumBadge';
import PremiumModal from '../components/PremiumModal';

export class DNS extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            new: true,
            subdomain: "",
            error: false,
            errorMessage: "",
            loading: false,
            ssl: {},
            cnameRecords: {
                name: "",
                value: ""
            },
            refreshLoading: false,
            isDeleteOpen: false,
            isDomainPromptOpen: false
        };

        this.refreshInterval = null;
    }

    componentDidMount() {
        if (this.props.bot.dns.custom) {
            this.refreshDNS();
        }
        this.refreshInterval = setInterval(this.refreshDNS, 10000);
    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    refreshDNS = async () => {
        if (!this.props.bot.dns.custom) return;

        this.setState({ refreshLoading: true });
        try {
            const response = await server.post("/dashboard/dns/refresh", {
                bot_id: this.props.bot.id,
                dns_id: this.props.bot.dns.id
            });
            if (response && response.data.success) {
                var dns = { ...this.props.bot.dns };
                dns.ssl = response.data.data.ssl;
                dns.status = response.data.data.status;
                this.props.updateBotSettings("dns", dns, false);
            }
        } catch (error) {
            console.error("Failed to refresh DNS:", error);
        } finally {
            this.setState({ refreshLoading: false });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.isOpen && prevState.isOpen) {
            // Reset values
            // this.setState({
            //     new: true,
            //     subdomain: "",
            //     error: false,
            //     errorMessage: "",
            //     loading: false,
            //     txtRecords: {
            //         name: "",
            //         value: ""
            //     },
            //     cnameRecords: {
            //         name: "",
            //         value: ""
            //     },
            //     new: true
            // });
        }
    }

    renderTarget = () => {
        // If its a subdomain, return the subdomain i.e mybot.example.com return mybot.
        // If its a root domain, return @

        if (this.props.bot.dns.url.includes(".") && this.props.bot.dns.url.split(".").length > 2) {
            var parts = this.props.bot.dns.url.split(".");
            return parts[0];
        } else {
            return "@";
        }


        // If its example.com return @

    };

    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.success('Copied to clipboard!', {
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        }).catch((err) => {
            console.error('Failed to copy: ', err);
            toast.error('Failed to copy', {
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
            });
        });
    };
    renderDomainTable = () => {

        return (
            <div className='mt-5 py-5'>
                <Divider className={"mb-5"} />
                <Heading>DNS Records</Heading>
                <Text>Add these records to our domain name provider's DNS settings. You can check the DNS records of your domain with <TextLink href="https://dnschecker.org/all-dns-records-of-domain.php">this tool</TextLink> </Text>

                <Table className={"mt-5"}>
                    <TableHead>
                        <TableRow>
                            <TableHeader>Record Type</TableHeader>
                            <TableHeader>Name / Host</TableHeader>
                            <TableHeader>Value/ Points To</TableHeader>
                            <TableHeader>TTL</TableHeader>

                        </TableRow>
                    </TableHead>

                    <TableBody>
                        <TableRow>
                            <TableCell>CNAME</TableCell>
                            <TableCell>                                <Field>
                                <Input value={

                                    this.renderTarget()
                                } disabled className="hover:cursor-pointer cursor-pointer" type="text" placeholder="" />
                                <Label className={"!text-xs !hover:cursor-pointer !cursor-pointer"} onClick={() => this.copyToClipboard(this.renderTarget())}>Copy</Label>
                            </Field></TableCell>
                            <TableCell>
                                <Field>
                                    <Input value={process.env.REACT_APP_CNAME} disabled className="hover:cursor-pointer cursor-pointer" type="text" placeholder="" />
                                    <Label className={"!text-xs !hover:cursor-pointer !cursor-pointer"} onClick={() => this.copyToClipboard(process.env.REACT_APP_CNAME)}>Copy</Label>
                                </Field>
                            </TableCell>
                            <TableCell>
                                Auto/Default
                            </TableCell>

                        </TableRow>
                        {
                            this.props.bot.dns?.ssl?.status === "pending_validation" && this.props.bot.dns?.ssl?.txt_name ?
                                <TableRow>
                                    <TableCell>TXT</TableCell>
                                    <TableCell>
                                        <Field>
                                            <Input value={this.props.bot.dns.ssl.txt_name} disabled className="hover:cursor-pointer cursor-pointer" type="text" placeholder="" />
                                            <Label className={"!text-xs !hover:cursor-pointer !cursor-pointer"} onClick={() => this.copyToClipboard(this.props.bot.dns.ssl.txt_name)}>Copy</Label>

                                        </Field>
                                    </TableCell>
                                    <TableCell>
                                        <Field>
                                            <Input value={this.props.bot.dns.ssl.txt_value} disabled className="hover:cursor-pointer cursor-pointer" type="text" placeholder="" />
                                            <Label className={"!text-xs !hover:cursor-pointer !cursor-pointer"} onClick={() => this.copyToClipboard(this.props.bot.dns.ssl.txt_value)}>Copy</Label>
                                        </Field>
                                    </TableCell>
                                    <TableCell>
                                        Auto/Default
                                    </TableCell>

                                </TableRow>
                                : null
                        }
                    </TableBody>

                </Table>

            </div>
        );
    };

    renderBadge = () => {

        if (this.props.bot.dns.status === "active" && this.props.bot.dns.ssl?.status === "active") {
            return <Badge color="green">Active</Badge>;
        }
        else if (this.props.bot.dns.status === "pending" || this.props.bot.dns.status === "pending_validation" || this.props.bot.dns?.ssl.status == "pending" || this.props.bot.dns?.ssl.status == "pending_validation") {
            return <Badge color="red"><ArrowPathIcon className='animate-spin h-4' /> Pending Validation</Badge>;
        }
        else if (this.props.bot.dns?.ssl?.status === "initializing") {
            return <Badge color="blue"><ArrowPathIcon className='animate-spin h-4' /> Initializing</Badge>;
        }




    };

    handleDomainPrompt = (hasDomain) => {
        if (hasDomain) {
            this.setState({
                isDomainPromptOpen: false,
                isOpen: true,
                new: true
            });
        } else {
            // Redirect to Namecheap
            window.open('https://www.namecheap.com/domains/', '_blank');
            this.setState({ isDomainPromptOpen: false });
        }
    };

    render() {

        return (
            <Dashboard>
                <Dialog
                    open={this.state.isDomainPromptOpen}
                    onClose={() => this.setState({ isDomainPromptOpen: false })}
                    size="lg"
                >
                    <DialogTitle>Do you have a domain?</DialogTitle>
                    <DialogDescription>
                        To set up a custom domain, you'll need to own a domain name first.
                    </DialogDescription>
                    <DialogBody>
                        <Text>
                            A domain name (like yourbot.com) is required to set up a custom domain for your bot panel.
                            If you don't have one, we recommend purchasing one from Namecheap.
                        </Text>
                    </DialogBody>
                    <DialogActions>
                        <Button plain onClick={() => this.setState({ isDomainPromptOpen: false })}>
                            Cancel
                        </Button>
                        <Button color="blue" onClick={() => this.handleDomainPrompt(false)}>
                            I need to buy a domain
                        </Button>
                        <Button onClick={() => this.handleDomainPrompt(true)}>
                            I have a domain
                        </Button>
                    </DialogActions>
                </Dialog>

                <PremiumModal requiredType="basic">
                    <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                        <div className="flex items-center gap-2">
                            <Heading>Domain Settings</Heading>
                            <PremiumBadge type="basic" />
                        </div>
                        <div className="flex gap-4">
                            <Button disabled={this.props.bot.dns.custom} className="ml-auto" onClick={() => {
                                this.setState({ isDomainPromptOpen: true });
                            }}><PlusIcon />Add Domain</Button>

                        </div>
                    </div>

                    {/*  */}
                    <Dialog onClose={() => {
                        this.setState({
                            isOpen: false
                        });
                        // this.setState({
                        //     // isOpen: false,
                        //     new: true,
                        //     subdomain: "",
                        //     error: false,
                        //     errorMessage: "",
                        //     loading: false,
                        //     txtRecords: {
                        //         name: "",
                        //         value: ""
                        //     },
                        //     cnameRecords: {
                        //         name: "",
                        //         value: ""
                        //     },
                        //     new: true
                        // });
                    }} size="5xl" open={this.state.isOpen}>
                        <DialogTitle>{this.state.new ? "New Domain" : "Domain Settings"}</DialogTitle>
                        <DialogDescription>
                            Add a new custom domain for your Bot Panel.
                        </DialogDescription>

                        <DialogBody>
                            <Field>
                                <Label>Domain</Label>
                                <Input disabled={!this.state.new} value={this.state.subdomain} invalid={this.state.error} name="domain" type="url" placeholder="mybot.botghost.com" onChange={(e) => {
                                    this.setState({
                                        subdomain: e.target.value
                                    });
                                }} />
                                {this.state.error && <ErrorMessage>{this.state.errorMessage}</ErrorMessage>}
                            </Field>

                            {
                                this.state.new ? <></> :
                                    <>
                                        <Divider className={"my-5"} />
                                        <div>

                                            <Heading>Instructions</Heading>
                                            <Text>Follow the instructions below to add your custom domain to your Bot Panel.</Text>

                                            <Text>1. Start by adding the DNS records shown below to your domain name provider's settings (like Cloudflare, Namecheap, GoDaddy or others).</Text>
                                            <Text>2.
                                                Wait until the DNS records are verified. This can take anywhere from a few seconds to a few hours due to the length of time your DNS takes to propagate. If it takes longer than expected, please double check if the configuration is the same as below.</Text>
                                            <Text>3. When your domain resolves to our servers, BotPanel will take care of hosting and issuing SSL Certificates for you.</Text>


                                        </div>

                                        <div>
                                            {this.renderDomainTable()}

                                        </div>
                                        <Divider className={"my-5"} />

                                    </>
                            }
                        </DialogBody>
                        <DialogActions>
                            <Button plain onClick={() => {
                                this.setState({
                                    isOpen: false
                                });
                            }}>
                                Cancel
                            </Button>
                            {this.state.new ? <>
                                <Button disabled={this.state.loading} onClick={async () => {
                                    // this.setState({ loading: true });
                                    var subdomain = this.state.subdomain;
                                    // Validate the subdomain. Ensure it is the root subdomain only or a subdomain. i.e botghost.com or mybot.botghost.com.
                                    // Clean it up.
                                    if (subdomain.includes("http://") || subdomain.includes("https://")) {
                                        this.setState({
                                            error: true,
                                            errorMessage: "Please enter a valid domain name without the protocol. For example: mybot.botghost.com or botghost.com",
                                            loading: false
                                        });
                                        return;
                                    }
                                    if (subdomain.includes("www.")) {
                                        this.setState({
                                            error: true,
                                            errorMessage: "Please enter a valid domain name without the www. prefix. For example: mybot.botghost.com or botghost.com",
                                            loading: false
                                        });
                                        return;
                                    }

                                    if (subdomain.includes(".")) {
                                        var parts = subdomain.split(".");
                                        if (parts.length < 2) {
                                            this.setState({
                                                error: true,
                                                errorMessage: "Please enter a valid domain name. For example: mybot.botghost.com or botghost.com",
                                                loading: false
                                            });
                                            return;
                                        }
                                    } else {
                                        this.setState({
                                            error: true,
                                            errorMessage: "Please enter a valid domain name. For example: mybot.botghost.com or botghost.com",
                                            loading: false
                                        });
                                        return;
                                    }

                                    // Validate for this Invalid custom hostname. Custom hostnames have to be smaller than 256 characters in length, cannot be IP addresses, cannot contain spaces, cannot contain any special characters such as _~`!@#$%^*()=+{}[]|\\;:\'",<>/? and cannot begin or end with a \'-\' character. Please check your input and try again.
                                    // Use regex to validate the subdomain.
                                    if (subdomain.length > 256) {
                                        this.setState({
                                            error: true,
                                            errorMessage: "Custom hostnames have to be smaller than 256 characters in length.",
                                            loading: false
                                        });
                                        return;
                                    }

                                    if (subdomain.includes(" ")) {
                                        this.setState({
                                            error: true,
                                            errorMessage: "Custom hostnames cannot contain spaces.",
                                            loading: false
                                        });
                                        return;
                                    }

                                    if (subdomain.includes("_") || subdomain.includes("~") || subdomain.includes("`") || subdomain.includes("!") || subdomain.includes("@") || subdomain.includes("#") || subdomain.includes("$") || subdomain.includes("%") || subdomain.includes("^") || subdomain.includes("*") || subdomain.includes("(") || subdomain.includes(")") || subdomain.includes("=") || subdomain.includes("+") || subdomain.includes("{") || subdomain.includes("}") || subdomain.includes("[") || subdomain.includes("]") || subdomain.includes("|") || subdomain.includes("\\") || subdomain.includes(";") || subdomain.includes(":") || subdomain.includes("'") || subdomain.includes('"') || subdomain.includes(",") || subdomain.includes("<") || subdomain.includes(">") || subdomain.includes("/") || subdomain.includes("?")) {
                                        this.setState({
                                            error: true,
                                            errorMessage: "Custom hostnames cannot contain special characters.",
                                            loading: false
                                        });
                                        return;
                                    }




                                    var response = await server.post("/dashboard/dns/add", {
                                        subdomain: this.state.subdomain,
                                        bot_id: this.props.bot.id
                                    }).catch(e => {

                                    });
                                    if (response && response.data.success) {
                                        this.setState({
                                            new: false,
                                            loading: false,
                                            isOpen: false
                                        });
                                        var dns = { ...this.props.bot.dns };
                                        dns = {
                                            id: response.data.data.id,
                                            url: response.data.data.hostname,
                                            ssl: response.data.data.ssl,
                                            status: response.data.data.status,
                                            custom: true
                                        };
                                        this.props.updateBotSettings("dns", dns, false);
                                    } else {
                                        this.setState({
                                            error: true,
                                            errorMessage: response.data.error,
                                            loading: false
                                        });
                                        return;

                                    }



                                }}>{this.state.loading ?
                                    <><ArrowPathIcon className='animate-spin' />Loading</>
                                    :
                                    "Add Domain"}</Button>
                            </> : null}


                        </DialogActions>
                    </Dialog>

                    <Alert open={this.state.isDeleteOpen} onClose={() => {
                        this.setState({
                            isDeleteOpen: false
                        });

                    }}>
                        <AlertTitle>Are you sure you want to delete your custom domain?</AlertTitle>
                        <AlertDescription>
                            This action is irreversible and will remove your custom domain from BotPanel. You will need to re-add the domain if you want to use it again.
                        </AlertDescription>
                        <AlertActions>
                            <Button plain onClick={() => {
                                this.setState({
                                    isDeleteOpen: false
                                });

                            }}>
                                Cancel
                            </Button>
                            <Button color="red" onClick={async () => {
                                // to-do
                                var response = await server.post("/dashboard/dns/delete", {
                                    bot_id: this.props.bot.id,
                                    dns_id: this.props.bot.dns.id
                                }).catch(e => {

                                });

                                if (response && response.data.success) {
                                    this.setState({
                                        isDeleteOpen: false
                                    });
                                    var dns = { ...this.props.bot.dns };
                                    dns = {
                                        id: "",
                                        url: "",
                                        ssl: {},
                                        status: "",
                                        custom: false
                                    };
                                    this.props.updateBotSettings("dns", dns, false);
                                } else {
                                    this.setState({
                                        isDeleteOpen: false
                                    });
                                }

                            }}>Delete Domain</Button>
                        </AlertActions>
                    </Alert>
                    <div className='mt-8'>



                        <div className='py-5 gap-y-5 flex flex-col'>

                            <div className='flex flex-col hover:cursor-pointer hover:opacity-60'>
                                <span className='text-white font-bold text-lg'>{this.props.bot.id}.{process.env.REACT_APP_ROOT_NAME} <Badge color="green">Active</Badge></span>
                                <span className='text-white opacity-70 text-sm'>{`https://www.${this.props.bot.id}.botpanel.gg`}</span>
                            </div>

                            {this.props.bot.dns.custom ? <>
                                <div className='flex items-center'>
                                    <div className='flex flex-col hover:cursor-pointer hover:opacity-60' onClick={() => {
                                        this.setState({
                                            new: false,
                                            isOpen: true,
                                            subdomain: this.props.bot.dns.url,
                                            ssl: this.props.bot.dns.ssl

                                        });

                                    }}>
                                        <span className='text-white font-bold text-lg'>{this.props.bot.dns.url} {this.renderBadge()}</span>
                                        <span className='text-white opacity-70 text-sm'>{`https://www.${this.props.bot.dns.url}`}</span>
                                    </div>

                                    <div className='ml-auto flex flex-col lg:flex-row gap-y-1 lg:gap-x-3'>
                                        <Button size="small" onClick={() => {
                                            this.setState({
                                                new: false,
                                                isOpen: true,
                                                subdomain: this.props.bot.dns.url,
                                                ssl: this.props.bot.dns.ssl

                                            });
                                        }}>Manage</Button>
                                        <Button disabled={this.state.refreshLoading} onClick={this.refreshDNS}>
                                            {this.state.refreshLoading ? 'Refreshing...' : 'Refresh'}
                                        </Button>
                                        <Button color="red" onClick={() => {
                                            this.setState({
                                                isDeleteOpen: true
                                            });
                                        }}>Delete</Button>
                                    </div>
                                </div>
                            </> : null}

                        </div>
                    </div>
                </PremiumModal>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot
});

const mapDispatchToProps = {
    updateBotSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(DNS);;