import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from './dialog';
import { Button } from './button';

const SettingsModal = ({ isOpen, onClose, sections, onSave }) => {
    const [localSections, setLocalSections] = useState(sections);

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(localSections);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setLocalSections(items);
    };

    const toggleSection = (index) => {
        const newSections = [...localSections];
        newSections[index].visible = !newSections[index].visible;
        setLocalSections(newSections);
    };

    return (
        <Dialog onClose={onClose} size="5xl" open={isOpen}>
            <DialogTitle>Analytics Settings</DialogTitle>
            <DialogDescription>
                Customize the visibility and order of analytics sections.
            </DialogDescription>

            <DialogBody>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="sections">
                        {(provided) => (
                            <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
                                {localSections.map((section, index) => (
                                    <Draggable key={section.id} draggableId={section.id} index={index}>
                                        {(provided) => (
                                            <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="flex items-center justify-between p-3 bg-gray-700 rounded-lg"
                                            >
                                                <span className="text-white">{section.title}</span>
                                                <label className="cursor-pointer label">
                                                    <input
                                                        type="checkbox"
                                                        checked={section.visible}
                                                        onChange={() => toggleSection(index)}
                                                        className="checkbox checkbox-primary"
                                                    />
                                                </label>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogBody>

            <DialogActions>
                <Button plain onClick={onClose}>
                    Cancel
                </Button>
                <Button onClick={() => onSave(localSections)}>
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingsModal;
