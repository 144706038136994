import React from 'react';
import { Select } from '../../components/select';

const TextSizeSelect = ({ value, onChange, label }) => {
    return (
        <Select
            value={value}
            onChange={onChange}
            className="mt-1 block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
            <option value="text-xs">Extra Small</option>
            <option value="text-sm">Small</option>
            <option value="text-base">Base</option>
            <option value="text-lg">Large</option>
            <option value="text-xl">Extra Large</option>
            <option value="text-2xl">2XL</option>
            <option value="text-3xl">3XL</option>
            <option value="text-4xl">4XL</option>
            <option value="text-5xl">5XL</option>
            <option value="text-6xl">6XL</option>
            <option value="text-7xl">7XL</option>
            <option value="text-8xl">8XL</option>
            <option value="text-9xl">9XL</option>
        </Select>
    );
};

export default TextSizeSelect;
