import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';


class NewsSettings extends Component {
    state = {
        layout: 1,
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        newsItems: []
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleNewsItemChange = (index, field, value) => {
        this.setState(prevState => {
            const updatedNewsItems = [...prevState.newsItems];
            updatedNewsItems[index] = { ...updatedNewsItems[index], [field]: value };
            return { newsItems: updatedNewsItems };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addNewsItem = () => {
        this.setState(prevState => ({
            newsItems: [...prevState.newsItems, { title: '', content: '', date: '' }]
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeNewsItem = (index) => {
        this.setState(prevState => ({
            newsItems: prevState.newsItems.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your news section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Default Layout</option>
                            <option value={2}>Compact Layout</option>
                            <option value={3}>Featured Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>News Items</Label>
                        <Description>Add or edit news items</Description>
                        {this.state.newsItems.map((item, index) => (
                            <div key={index} className="mt-4 p-4 border border-gray-300 rounded-md">
                                <Field>
                                    <Label>News Title</Label>
                                    <Input
                                        value={item.title || ''}
                                        onChange={(e) => this.handleNewsItemChange(index, 'title', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>News Content</Label>
                                    <Input
                                        value={item.content || ''}
                                        onChange={(e) => this.handleNewsItemChange(index, 'content', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Date</Label>
                                    <Input
                                        type="date"
                                        value={item.date || ''}
                                        onChange={(e) => this.handleNewsItemChange(index, 'date', e.target.value)}
                                    />
                                </Field>
                                <Button onClick={() => this.removeNewsItem(index)} className="mt-4">Remove News Item</Button>
                            </div>
                        ))}
                        <Button onClick={this.addNewsItem} className="mt-4">Add News Item</Button>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default NewsSettings;
