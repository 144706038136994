import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import { Textarea } from '../../components/textarea';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';

class TestimonialsSettings extends Component {
    state = {
        layout: 1,
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        members: []
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleMemberChange = (index, field, value) => {
        this.setState(prevState => {
            const updatedMembers = [...prevState.members];
            updatedMembers[index] = { ...updatedMembers[index], [field]: value };
            return { members: updatedMembers };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addMember = () => {
        this.setState(prevState => ({
            members: [...prevState.members, { name: '', role: '', message: '', image: { src: '' } }]
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeMember = (index) => {
        this.setState(prevState => ({
            members: prevState.members.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your testimonials section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Grid Layout</option>
                            <option value={2}>Carousel Layout</option>
                            <option value={3}>List Layout</option>
                            <option value={4}>Compact Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <Field>
                        <Label>Testimonials</Label>
                        <Description>Add or edit testimonials</Description>
                        {this.state.members.map((member, index) => (
                            <div key={index} className="mt-4 p-4 border border-gray-300 rounded-md">
                                <Field>
                                    <Label>Name</Label>
                                    <Input
                                        value={member.name || ''}
                                        onChange={(e) => this.handleMemberChange(index, 'name', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Role</Label>
                                    <Input
                                        value={member.role || ''}
                                        onChange={(e) => this.handleMemberChange(index, 'role', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Message</Label>
                                    <Textarea
                                        value={member.message || ''}
                                        onChange={(e) => this.handleMemberChange(index, 'message', e.target.value)}
                                        rows={3}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Image URL</Label>
                                    <Input
                                        value={member.image?.src || ''}
                                        onChange={(e) => this.handleMemberChange(index, 'image', { src: e.target.value })}
                                    />
                                </Field>
                                <Button onClick={() => this.removeMember(index)} className="mt-4">Remove Testimonial</Button>
                            </div>
                        ))}
                        <Button onClick={this.addMember} className="mt-4">Add Testimonial</Button>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default TestimonialsSettings;
