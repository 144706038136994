import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Heading } from '../components/heading';
import { Description, Field, FieldGroup, Fieldset, Label, Legend } from '../components/fieldset';
import { Input } from '../components/input';
import { Select } from '../components/select';
import { Strong, Text, TextLink } from '../components/text';
import { Textarea } from '../components/textarea';
import { Switch, SwitchField } from '../components/switch';
import { updateBotSettings, updateBotData } from '../actions';
import { Button } from '../components/button';
import server from '../utils/server';

export class PanelSettings extends Component {
    state = {
        isRefreshing: false,
        refreshSuccess: false
    };

    renderRedirectURL = () => {
        if (this.props.data.bot.dns.custom) {
            return `https://${this.props.data.bot.dns.url}/redirect`;
        } else {
            return `https://${this.props.data.bot.default_domain}/redirect`;
        }
    };

    render() {
        return (
            <Dashboard header="Panel Settings">
                <div className='flex gap-y-10 flex-col'>
                    <section>
                        <form action="/orders" method="POST">
                            {/* ... */}
                            <Fieldset>
                                <Legend>Page Settings</Legend>
                                <Text>Basic bot panel page settings</Text>
                                <FieldGroup>
                                    <Field>
                                        <Label>Title</Label>
                                        <Description>Your panels default page title. If left blank this will be your bots name</Description>
                                        <Input onChange={(e) => {

                                            var dash_settings = { ...this.props.data.bot.dash_settings };
                                            dash_settings.title = e.target.value;
                                            this.props.updateBotSettings('dash_settings', dash_settings);

                                        }} name="street_address" value={this.props.data.bot.dash_settings.title} />
                                    </Field>

                                    <Field>
                                        <Label>Description</Label>
                                        <Description>Your panels default page description. If left blank this will be your bots name</Description>
                                        <Input onChange={(e) => {

                                            var dash_settings = { ...this.props.data.bot.dash_settings };
                                            dash_settings.description = e.target.value;
                                            this.props.updateBotSettings('dash_settings', dash_settings);

                                        }} name="street_address" value={this.props.data.bot.dash_settings.description} />
                                    </Field>

                                    <Field>
                                        <Label>Support Server URL</Label>
                                        <Description>A Discord server invite URL for your bot.</Description>
                                        <Input onChange={(e) => {

                                            var dash_settings = { ...this.props.data.bot.dash_settings };
                                            dash_settings.support = e.target.value;
                                            this.props.updateBotSettings('dash_settings', dash_settings);

                                        }} name="street_address" value={this.props.data.bot.dash_settings.support} />
                                    </Field>

                                </FieldGroup>
                            </Fieldset>
                            {/* ... */}
                        </form>
                    </section>

                    <hr></hr>

                    <section>
                        <form action="/orders" method="POST">
                            {/* ... */}
                            <Fieldset>
                                <Legend>Color Settings</Legend>
                                <Text>Brand colors and bot icon</Text>
                                <FieldGroup>
                                    <Field>
                                        <div className="flex items-center justify-between">
                                            <div>
                                                <Label>Primary</Label>
                                                <Description>The primary color for all buttons and elements. This should be your main brand color</Description>
                                            </div>
                                            <label className="relative w-[50px] h-[50px] cursor-pointer">
                                                <input
                                                    type="color"
                                                    onChange={(e) => {
                                                        var color_settings = { ...this.props.data.bot.color_settings };
                                                        color_settings.primary = e.target.value;
                                                        this.props.updateBotSettings('color_settings', color_settings);
                                                    }}
                                                    name="primary_color"
                                                    value={this.props.data.bot.color_settings.primary}
                                                    className="sr-only"
                                                />
                                                <div
                                                    className="absolute inset-0 rounded border-2 border-gray-500"
                                                    style={{ backgroundColor: this.props.data.bot.color_settings.primary }}
                                                ></div>
                                            </label>
                                        </div>
                                    </Field>

                                    <Field>
                                        <div className="flex justify-between items-center">
                                            <div>
                                                <Label>Refresh Bot Icon and Name</Label>
                                                <Description>Click the button to refresh your Discord bot's icon and name</Description>
                                                {this.state.refreshSuccess && (
                                                    <Text className="text-green mt-2">Refreshed!</Text>
                                                )}
                                            </div>
                                            <Button
                                                onClick={this.refreshBotIcon}
                                                className="float-right"
                                                disabled={this.state.isRefreshing}
                                            >
                                                {this.state.isRefreshing ? 'Refreshing...' : 'Refresh Bot'}
                                            </Button>
                                        </div>
                                    </Field>
                                </FieldGroup>
                            </Fieldset>
                            {/* ... */}
                        </form>
                    </section>

                    <hr></hr>
                    <section>
                        <form action="/orders" method="POST">
                            {/* ... */}
                            <Fieldset>
                                <Legend>Invite Settings</Legend>
                                <Text>Permission settings and redirect options for your Bot's invite URL.</Text>
                                <FieldGroup>
                                    <Field>
                                        <Label>Permissions Integer</Label>
                                        <Description>Which permissions your bot should request when being invited to a server</Description>
                                        <Input onChange={(e) => {

                                            var invite_settings = { ...this.props.data.bot.invite_settings };
                                            invite_settings.invite_perms_int = e.target.value;
                                            this.props.updateBotSettings('invite_settings', invite_settings);

                                        }} name="street_address" type="number" value={this.props.data.bot.invite_settings.invite_perms_int} />
                                    </Field>

                                    <Field>
                                        <SwitchField>
                                            <Label>Invite Redirect</Label>
                                            <Description>Whether to redirect the user to the Bot Panel after they have invited the bot.</Description>
                                            <Switch color="sky" checked={this.props.data.bot.invite_settings.redirect} onChange={(value) => {

                                                var invite_settings = { ...this.props.data.bot.invite_settings };
                                                invite_settings.redirect = value;
                                                this.props.updateBotSettings('invite_settings', invite_settings);
                                            }} name="allow_embedding" value={this.props.data.bot.invite_settings.redirect} />
                                        </SwitchField>
                                        <Description>You must add the redirect url <Strong>{this.renderRedirectURL()}</Strong> to your bot's <TextLink href={`https://discord.com/developers/applications/${this.props.data.bot.id}/oauth2`}>OAuth2 Redirects.</TextLink></Description>

                                    </Field>


                                </FieldGroup>
                            </Fieldset>
                            {/* ... */}
                        </form>
                    </section>

                    <hr></hr>

                    <section>
                        <form action="/orders" method="POST">
                            <Fieldset>
                                <Legend>Custom Error Messages</Legend>
                                <Text>Customize the command error messages for your users.</Text>
                                <FieldGroup>
                                    <Field>
                                        <SwitchField>
                                            <Label>Use Embed for Error Messages</Label>
                                            <Description>Toggle between embed and plain text for error messages</Description>
                                            <Switch
                                                color="sky"
                                                checked={this.props.data.bot.dash_settings?.error_messages?.use_embed || false}
                                                onChange={(value) => {
                                                    const dash_settings = {
                                                        ...this.props.data.bot.dash_settings,
                                                        error_messages: {
                                                            ...this.props.data.bot.dash_settings.error_messages,
                                                            use_embed: value
                                                        }
                                                    };
                                                    this.props.updateBotSettings('dash_settings', dash_settings);
                                                }}
                                                name="use_embed"
                                            />
                                        </SwitchField>
                                    </Field>

                                    {this.props.data.bot.dash_settings?.error_messages?.use_embed && (
                                        <Field>
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <Label>Embed Color</Label>
                                                    <Description>Choose a color for the error message embed</Description>
                                                </div>
                                                <label className="relative w-[50px] h-[50px] cursor-pointer">
                                                    <input
                                                        type="color"
                                                        onChange={(e) => {
                                                            const dash_settings = {
                                                                ...this.props.data.bot.dash_settings,
                                                                error_messages: {
                                                                    ...this.props.data.bot.dash_settings.error_messages,
                                                                    embed_color: e.target.value
                                                                }
                                                            };
                                                            this.props.updateBotSettings('dash_settings', dash_settings);
                                                        }}
                                                        name="embed_color"
                                                        value={this.props.data.bot.dash_settings?.error_messages?.embed_color || '#ff0000'}
                                                        className="sr-only"
                                                    />
                                                    <div
                                                        className="absolute inset-0 rounded border-2 border-gray-500"
                                                        style={{ backgroundColor: this.props.data.bot.dash_settings?.error_messages?.embed_color || '#ff0000' }}
                                                    ></div>
                                                </label>
                                            </div>
                                        </Field>
                                    )}

                                    <Field>
                                        <Label>Command Not Enabled</Label>
                                        <Description>Error message when a user tries to use a disabled command</Description>
                                        <Input
                                            onChange={(e) => {
                                                const dash_settings = {
                                                    ...this.props.data.bot.dash_settings,
                                                    error_messages: {
                                                        ...this.props.data.bot.dash_settings.error_messages,
                                                        command_disabled: e.target.value
                                                    }
                                                };
                                                this.props.updateBotSettings('dash_settings', dash_settings);
                                            }}
                                            name="command_disabled"
                                            value={this.props.data.bot.dash_settings?.error_messages?.command_disabled || ''}
                                            placeholder="This command is currently disabled."
                                        />
                                    </Field>

                                    <Field>
                                        <Label>Missing Permissions</Label>
                                        <Description>Error message when a user lacks the required permissions</Description>
                                        <Input
                                            onChange={(e) => {
                                                const dash_settings = {
                                                    ...this.props.data.bot.dash_settings,
                                                    error_messages: {
                                                        ...this.props.data.bot.dash_settings.error_messages,
                                                        missing_permissions: e.target.value
                                                    }
                                                };
                                                this.props.updateBotSettings('dash_settings', dash_settings);
                                            }}
                                            name="missing_permissions"
                                            value={this.props.data.bot.dash_settings?.error_messages?.missing_permissions || ''}
                                            placeholder="You don't have the required permissions to use this command."
                                        />
                                    </Field>

                                    <Field>
                                        <Label>Disabled Channel</Label>
                                        <Description>Error message when a command is used in a disabled channel</Description>
                                        <Input
                                            onChange={(e) => {
                                                const dash_settings = {
                                                    ...this.props.data.bot.dash_settings,
                                                    error_messages: {
                                                        ...this.props.data.bot.dash_settings.error_messages,
                                                        disabled_channel: e.target.value
                                                    }
                                                };
                                                this.props.updateBotSettings('dash_settings', dash_settings);
                                            }}
                                            name="disabled_channel"
                                            value={this.props.data.bot.dash_settings?.error_messages?.disabled_channel || ''}
                                            placeholder="This command cannot be used in this channel."
                                        />
                                    </Field>

                                    <Field>
                                        <Label>Missing Required Role</Label>
                                        <Description>Error message when a user doesn't have the required role</Description>
                                        <Input
                                            onChange={(e) => {
                                                const dash_settings = {
                                                    ...this.props.data.bot.dash_settings,
                                                    error_messages: {
                                                        ...this.props.data.bot.dash_settings.error_messages,
                                                        missing_role: e.target.value
                                                    }
                                                };
                                                this.props.updateBotSettings('dash_settings', dash_settings);
                                            }}
                                            name="missing_role"
                                            value={this.props.data.bot.dash_settings?.error_messages?.missing_role || ''}
                                            placeholder="You don't have the required role to use this command."
                                        />
                                    </Field>
                                </FieldGroup>
                            </Fieldset>
                        </form>
                    </section>
                </div>
                {/* Dashboard Settings (title, description, support URL) */}


                {/* Color Settings */}



                {/* Invite Settings (perms int, redirect) */}
            </Dashboard>
        );
    }

    refreshBotIcon = async () => {
        this.setState({ isRefreshing: true, refreshSuccess: false });
        try {
            const response = await server.post('/dashboard/refresh-bot-icon', {
                botId: this.props.data.bot.id
            });

            if (response.data.success) {
                this.setState({ refreshSuccess: true });
                // Update the bot's icon and name in the Redux store

            } else {
                throw new Error(response.data.error || 'Failed to refresh bot icon and name');
            }
        } catch (error) {
            console.error('Error refreshing bot icon and name:', error);
            alert('Failed to refresh bot icon and name. Please try again.');
        } finally {
            this.setState({ isRefreshing: false });
            // Reload the page
            window.location.reload();
            // Reset the success message after 5 seconds
            setTimeout(() => this.setState({ refreshSuccess: false }), 5000);
        }
    };
}

const mapStateToProps = (state) => ({
    data: state.data,
});

const mapDispatchToProps = {
    updateBotSettings: updateBotSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelSettings);
