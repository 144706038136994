import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { Subheading } from '../components/heading';
import { Avatar } from '../components/avatar';
import { Badge } from '../components/badge';
import { Divider } from '../components/divider';
import { Heading } from '../components/heading';
import { Select } from '../components/select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../components/table';
import OverviewStatsSection from '../components/OverviewStatsSection';
import TestDataWatermark from '../components/TestDataWatermark';

export function Stat({ title, value, change }) {
    return (
        <div>
            <Divider />
            <div className="mt-6 text-lg/6 text-white font-medium sm:text-sm/6">{title}</div>
            <div className="mt-3 text-3xl/8 text-white  font-semibold sm:text-2xl/8">{nFormatter(value, 1)}</div>
            <div className="mt-3 text-sm/6 sm:text-xs/6">
                <Badge color={change.startsWith('+') ? 'lime' : 'pink'}>{change}</Badge>{' '}
                <span className="text-zinc-500">from last week</span>
            </div>
        </div>
    );
}

const nFormatter = (num, digits) => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

// Dummy data for top stats
const topStatsData = {
    currentServerCount: 1213,
    currentMemberCount: 422,
    commandsUsedLifetime: 311,
    viewsLifetime: 22,
    serverSparkline: [1100, 1200, 1150, 1300, 1200, 1300, 1213],
    memberSparkline: [400, 410, 405, 415, 420, 418, 422],
    commandSparkline: [280, 290, 300, 305, 308, 310, 311],
    viewSparkline: [15, 17, 19, 20, 21, 22, 22]
};

// Dummy data for revenue stats
const revenueStatsData = {
    revenueThisMonth: {
        expected: 16420,
        received: 11860,
        lastMonth: 19830
    },
    todayRevenue: {
        expected: 324.98,
        received: 294.57,
        yesterday: 684.10
    },
    signupsThisMonth: {
        current: 221,
        lastMonth: 419
    },
    signupsToday: {
        current: 6,
        yesterday: 15
    }
};

export class Home extends Component {
    render() {
        return (
            <Dashboard>
                {/* Alert for open beta */}
                <div className="bg-red-900 mb-4 border-l-4 border-red-500 text-white p-4" role="alert">
                    <p className="font-bold">Open Beta (Scuffed Version)</p>
                    <p>BotPanel is currently in open beta. Some features may not be fully functional or even added. You are currently on a premium plan, however this will not last once the product is out of beta. Please report any bugs you find to the developers at <a href="https://discord.gg/botghost" className="text-blue-500">https://discord.gg/botghost</a>.</p>
                    <p className='mt-2'>This current version is extremely scuffed and is not recommended for use in production environments.</p>
                </div>

                <>
                    <Heading>Welcome Back</Heading>

                    {/* Overview Stats Section */}
                    <div>
                        {this.props.bot?.subscription?.type === "free" && <TestDataWatermark />}
                        <OverviewStatsSection placeholder={this.props.bot?.subscription?.type === "free"} topStatsData={topStatsData} revenueStatsData={revenueStatsData} />
                    </div>

                    {/* ... (any other sections you want to keep) ... */}
                </>

            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.data.user,
    stats: state.data.stats,
    bot: state.data.bot,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
