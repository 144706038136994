import server from "../utils/server";
import { setToken, getToken } from "../utils/auth";
import Cookies from "universal-cookie";
export const setAuth = (token = null, history = null) => {
  return async (dispatch) => {
    if (token) {
      setToken(token);
      dispatch({ type: "SET_AUTH", payload: true });
      if (history) {
        history.push("/dashboard");
      }
    } else {
      const existingToken = getToken();
      if (existingToken) {
        try {
          const response = await server.get("/dashboard/auth/status");

          if (response?.data?.authenticated) {
            dispatch({ type: "SET_AUTH", payload: true });
          } else {
            localStorage.removeItem('token');
            redirectToLogin();
          }
        } catch (error) {
          console.error("Error verifying authentication:", error);
          redirectToLogin();
        }
      } else {
        redirectToLogin();
      }
    }
  };
};
const redirectToLogin = () => {
  var domain = window.location.origin;

  // return;

  window.location.replace(
    `${process.env.REACT_APP_API_SERVER}/dashboard/auth/login?returnTo=${domain}/auth/callback?redirect=${encodeURIComponent(window.location.pathname)}`
  );

};

export const getData = () => {
  return async (dispatch, getState) => {
    const response = await server.get("/dashboard/data").catch((e) => { });

    if (response?.data) {
      // If bots length == 0 and its not /panel/new
      if (response.data?.bots?.length == 0 && window.location.pathname != "/panel/new") {
        console.log("No bots found");
        window.location.href = "/panel/new";
        return;
      }
      if (!response?.data.bot?.color_settings) {
        response.data.bot.color_settings = {
          primary: "#f45142",
        };
      }
      dispatch({ type: "SET_DATA", payload: response.data });
    }

    if (response?.data?.user) {
      dispatch({ type: "SET_AUTH", payload: true });
    }
  };
};

export const resetModuleSettings = (module_id) => {
  return async (dispatch, getState) => {
    var serverSettings = { ...getState().data.serverSettings };
    if (!serverSettings.moduleSettings) {
      serverSettings.moduleSettings = {};
    }

    var moduleSettings = { ...serverSettings.moduleSettings };
    delete moduleSettings[module_id];
    serverSettings.moduleSettings = moduleSettings;
    dispatch({ type: "UPDATE_SERVER_SETTINGS", payload: serverSettings });
  };

};

export const updateBotSettings = (key, value, updateRequired) => {
  return async (dispatch, getState) => {
    var botSettings = { ...getState().data.bot };
    botSettings[key] = value;
    if (updateRequired != false) {
      dispatch({ type: "UPDATE_BOT_SETTINGS", payload: botSettings });
    } else {
      dispatch({ type: "UPDATE_BOT_SETTINGS_NO_SAVE", payload: botSettings });
    }

  };
};
export const setLoading = (val) => {
  return async (dispatch, getState) => {
    dispatch({ type: "SET_LOADING", payload: val });
  };

};

export const updateBotSaveRequired = () => {
  return async (dispatch, getState) => {
    dispatch({ type: "UPDATE_BOT_SAVE_REQUIRED" });
  };
};

export const setServerData = (data) => {
  return async (dispatch, getState) => {
    data.loaded = true;
    dispatch({ type: "SET_SERVER_DATA", payload: data });
  };
};

export const setActiveServer = (server_id) => {
  return async (dispatch, getState) => {
    const activeServer = getState().data.servers.find((s) => s.id == server_id);

    var response = await server.get(
      "/client/server/" + server_id + "/settings",
      {}
    );

    if (activeServer) {
      dispatch({ type: "SET_ACTIVE_SERVER", payload: activeServer });
    }

    if (response?.data) {
      dispatch({ type: "SET_ACTIVE_SERVER_SETTINGS", payload: response.data });
    }
  };
};

const getDashboardData = () => {
  return async (dispatch) => {
    const token = localStorage.getItem('jwt');
    const response = await server.get("/api/client/data", {
      headers: {
        Authorization: `Bearer ${token}`,
        "host-name": window.location.hostname
      }
    });
    if (response?.data) {
      dispatch({ type: "SET_DASHBOARD_DATA", payload: response.data });
    }
  };
};
