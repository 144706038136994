import React, { useState } from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import ReactApexChart from 'react-apexcharts';
import SettingsModal from '../components/SettingsModal';
import { Button } from '../components/button';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import ExampleDataWatermark from '../components/ExampleDataWatermark';
import RevenueStatsSection from '../components/RevenueStatsSection';
import { Select } from '../components/select';
import OverviewStatsSection from '../components/OverviewStatsSection';
import moment from 'moment';
import { Heading } from '../components/heading';
import PremiumBadge from '../components/PremiumBadge';
import PremiumModal from '../components/PremiumModal';
import TestDataWatermark from '../components/TestDataWatermark';

// Helper function to safely parse dates
const safeParseDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
};

const formatCurrency = (value) => {
    if (typeof value === 'number') {
        return `$${value.toFixed(2)}`;
    }
    return value; // Already formatted
};

const Analytics = ({ color_settings, isPro, modules, analytics, servers, bot }) => {
    // Move parseDate function to the top
    const parseDate = (dateObj) => {
        if (dateObj && dateObj.$date) {
            // Handle ISODate format from MongoDB
            return moment(dateObj.$date);
        } else if (dateObj && typeof dateObj === 'string') {
            // Handle string date format
            return moment(dateObj);
        } else {
            console.error('Invalid date object:', dateObj);
            return null;
        }
    };

    // Helper function to get server name
    const getServerName = (serverId) => {
        const server = servers.find(s => s.id === serverId);
        return server ? server.name : `Unknown Server (${serverId})`;
    };

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [sections, setSections] = useState([
        { id: 'messageAnalytics', title: 'Message Analytics', visible: true },
        { id: 'memberAnalytics', title: 'Member Analytics', visible: true },
        { id: 'viewsAnalytics', title: 'Views Analytics', visible: true },
        { id: 'moduleCommandAnalytics', title: 'Module & Command Analytics', visible: true },
    ]);

    // Check if analytics data is available
    const hasAnalytics = Array.isArray(analytics) && analytics.length > 0;

    // Ensure bot and bot.subscription are defined
    const subscriptionType = bot?.subscription?.type || 'free';

    // Dummy data for top stats
    const dummyTopStatsData = {
        currentServerCount: 17823,
        serverCountChange: 12.5,
        serverSparkline: [17234, 17456, 17678, 17800, 17823],
        currentMemberCount: 23456,
        memberCountChange: 8.7,
        memberSparkline: [22000, 22500, 23000, 23300, 23456],
        commandsUsedLifetime: 9876,
        commandsUsedChange: 14.3,
        commandSparkline: [9000, 9200, 9400, 9600, 9876],
        viewsLifetime: 54321,
        viewsChange: 19.8,
        viewSparkline: [50000, 51000, 52000, 53000, 54321],
        activeUsersToday: 850,
        activeUsersChange: 4.3,
        activeUsersSparkline: [800, 820, 835, 845, 850],
        messagesSentToday: 67890,
        messagesSentChange: 22.1,
        messagesSentSparkline: [65000, 66000, 67000, 67500, 67890],
        newServersToday: 123,
        newServersChange: 3.4,
        newServersSparkline: [100, 110, 115, 120, 123],
        premiumSubscriptions: 150,
        premiumSubscriptionsChange: 12.5,
        premiumSubscriptionsSparkline: [130, 135, 140, 145, 150],
    };

    // Dummy data for graphs
    const dummyGraphData = {
        messagesReceivedTotal: [
            { x: new Date('2023-01-01').getTime(), y: 100 },
            { x: new Date('2023-01-02').getTime(), y: 150 },
            { x: new Date('2023-01-03').getTime(), y: 200 },
        ],
        messagesReceivedByGuild: [
            { guild: 'Guild 1', messages: 100 },
            { guild: 'Guild 2', messages: 150 },
        ],
        messagesReceivedPerDay: [
            { x: new Date('2023-01-01').getTime(), 'Guild 1': 50, 'Guild 2': 50 },
            { x: new Date('2023-01-02').getTime(), 'Guild 1': 75, 'Guild 2': 75 },
        ],
        membersHandledTotal: [
            { x: new Date('2023-01-01').getTime(), y: 50 },
            { x: new Date('2023-01-02').getTime(), y: 75 },
        ],
        membersHandledByGuild: [
            { guild: 'Guild 1', members: 30 },
            { guild: 'Guild 2', members: 45 },
        ],
        membersHandledPerDay: [
            { date: 'Jan 01', 'Guild 1': 15, 'Guild 2': 15 },
            { date: 'Jan 02', 'Guild 1': 20, 'Guild 2': 25 },
        ],
        viewsTotalForPeriod: [
            { x: new Date('2023-01-01').getTime(), y: 300 },
            { x: new Date('2023-01-02').getTime(), y: 400 },
        ],
        viewsPerRoute: [
            { route: '/', views: 200 },
            { route: '/dashboard', views: 300 },
        ],
        viewsPerDayPerRoute: [
            { x: new Date('2023-01-01').getTime(), '/': 100, '/dashboard': 200 },
            { x: new Date('2023-01-02').getTime(), '/': 150, '/dashboard': 250 },
        ],
        moduleEnableByName: [
            { module: 'Module 1', name: 'Module 1', enabled: 10, disabled: 5 },
            { module: 'Module 2', name: 'Module 2', enabled: 15, disabled: 10 },
        ],
        commandUsageTotal: [
            { x: new Date('2023-01-01').getTime(), y: 500 },
            { x: new Date('2023-01-02').getTime(), y: 600 },
        ],
        commandUsagePerDay: [
            { date: 'Jan 01', 'Command 1': 250, 'Command 2': 250 },
            { date: 'Jan 02', 'Command 1': 300, 'Command 2': 300 },
        ],
    };

    // Use dummy data if subscription type is 'free'
    const topStatsData = subscriptionType === 'free' ? dummyTopStatsData : {
        currentServerCount: 1213,
        serverCountChange: 5.2,
        serverSparkline: [1100, 1150, 1180, 1200, 1213],
        currentMemberCount: 422,
        memberCountChange: 3.8,
        memberSparkline: [400, 405, 410, 418, 422],
        commandsUsedLifetime: 311,
        commandsUsedChange: 7.5,
        commandSparkline: [280, 290, 300, 305, 311],
        viewsLifetime: 22,
        viewsChange: 10.0,
        viewSparkline: [18, 19, 20, 21, 22],
        activeUsersToday: 850,
        activeUsersChange: 4.3,
        activeUsersSparkline: [800, 820, 835, 845, 850],
        messagesSentToday: 15000,
        messagesSentChange: 6.7,
        messagesSentSparkline: [13500, 14000, 14500, 14800, 15000],
        newServersToday: 25,
        newServersChange: 8.7,
        newServersSparkline: [20, 22, 23, 24, 25],
        premiumSubscriptions: 150,
        premiumSubscriptionsChange: 12.5,
        premiumSubscriptionsSparkline: [130, 135, 140, 145, 150],
    };

    // Move all the processing functions here, after parseDate and getServerName
    const processMessageAnalytics = (data) => {
        console.log("Raw message data:", data); // Log raw data for debugging

        // Get all unique guild IDs across all days
        const allGuildIds = new Set();
        data.forEach(item => {
            Object.keys(item.messages || {}).forEach(guildId => allGuildIds.add(guildId));
        });

        const messagesReceivedTotal = data.map(item => {
            const date = parseDate(item.date);
            return date ? {
                x: date.valueOf(),
                y: Object.values(item.messages || {}).reduce((sum, count) => sum + count, 0)
            } : null;
        }).filter(item => item !== null);

        const messagesReceivedByGuild = Array.from(allGuildIds).map(guildId => ({
            guild: getServerName(guildId),
            messages: data.reduce((sum, item) => sum + (item.messages?.[guildId] || 0), 0)
        }));

        const messagesReceivedPerDay = data.map(item => {
            const date = parseDate(item.date);
            if (!date) return null;

            const dayData = {
                x: date.valueOf(),
            };

            // Include all guilds, with 0 if no data for that day
            allGuildIds.forEach(guildId => {
                dayData[getServerName(guildId)] = item.messages?.[guildId] || 0;
            });

            return dayData;
        }).filter(item => item !== null);

        console.log("Processed message data:", {
            messagesReceivedTotal,
            messagesReceivedByGuild,
            messagesReceivedPerDay
        }); // Log processed data for debugging

        return {
            messagesReceivedTotal,
            messagesReceivedByGuild,
            messagesReceivedPerDay
        };
    };

    const processMemberAnalytics = (data) => {
        const membersHandledTotal = data.map(item => {
            const date = parseDate(item.date);
            return date ? {
                x: date.valueOf(),
                y: Object.values(item.member || {}).reduce((sum, count) => sum + count, 0)
            } : null;
        }).filter(item => item !== null);

        const membersHandledByGuild = Object.entries(data[0]?.member || {}).map(([guildId, count]) => ({
            guild: getServerName(guildId),
            members: count
        }));

        const membersHandledPerDay = data.map(item => {
            const date = parseDate(item.date);
            return date ? {
                date: date.format('MMM DD'),
                ...Object.entries(item.member || {}).reduce((acc, [guildId, count]) => {
                    acc[getServerName(guildId)] = count;
                    return acc;
                }, {})
            } : null;
        }).filter(item => item !== null);

        return {
            membersHandledTotal,
            membersHandledByGuild,
            membersHandledPerDay
        };
    };

    const processViewsAnalytics = (data) => {
        console.log("Raw views data:", data); // Log raw data

        const viewsTotalForPeriod = data.map(item => {
            const date = parseDate(item.date);
            return date ? {
                x: date.valueOf(),
                y: Object.values(item.views || {}).reduce((sum, count) => sum + count, 0)
            } : null;
        }).filter(item => item !== null);

        const viewsPerRoute = Object.entries(data[0]?.views || {}).map(([route, count]) => ({
            route,
            views: count
        }));

        const viewsPerDayPerRoute = data.map(item => {
            const date = parseDate(item.date);
            return date ? {
                x: date.valueOf(),
                ...item.views
            } : null;
        }).filter(item => item !== null);

        const viewsPerDayPerServer = data.map(item => {
            const date = parseDate(item.date);
            if (!date) return null;
            const serverViews = Object.entries(item.views_by_guild || {}).reduce((acc, [guildId, count]) => {
                acc[getServerName(guildId)] = count;
                return acc;
            }, {});
            return { x: date.valueOf(), ...serverViews };
        }).filter(item => item !== null);

        const viewsPerServer = Object.entries(data[0]?.views_by_guild || {}).map(([serverId, count]) => ({
            server: getServerName(serverId),
            views: count
        }));

        console.log("Processed viewsPerDayPerServer:", viewsPerDayPerServer); // Log processed data

        return {
            viewsTotalForPeriod,
            viewsPerRoute,
            viewsPerDayPerRoute,
            viewsPerDayPerServer,
            viewsPerServer
        };
    };

    const processModuleCommandAnalytics = (data, modules) => {
        const moduleEnableByName = Object.entries(data[0]?.modules || {}).map(([moduleId, stats]) => ({
            module: moduleId,
            name: modules.find(module => module.id === moduleId)?.name || moduleId,
            enabled: stats.enables || 0,
            disabled: stats.disables || 0
        }));

        const commandIdToName = {};
        modules.forEach(module => {
            module.commands.forEach(command => {
                commandIdToName[command.int_id] = command.name;
            });
        });

        const commandUsageTotal = data.map(item => {
            const date = parseDate(item.date);
            return date ? {
                x: date.valueOf(),
                y: Object.values(item.commands || {}).reduce((sum, guildCommands) =>
                    sum + Object.values(guildCommands).reduce((guildSum, count) => guildSum + count, 0), 0)
            } : null;
        }).filter(item => item !== null);

        const commandUsagePerDay = data.map(item => {
            const date = moment(parseDate(item.date)).format('MMM DD');
            const commandCounts = {};
            Object.values(item.commands || {}).forEach(guildCommands => {
                Object.entries(guildCommands).forEach(([commandId, count]) => {
                    const commandName = commandIdToName[commandId] || commandId;
                    commandCounts[commandName] = (commandCounts[commandName] || 0) + count;
                });
            });
            return {
                date,
                ...commandCounts
            };
        });

        // Add these new processing functions
        const commandUsagePerGuild = Object.entries(data[0]?.commands || {}).map(([guildId, commands]) => ({
            guild: getServerName(guildId),
            usage: Object.values(commands).reduce((sum, count) => sum + count, 0)
        }));

        const commandUsagePerDayPerGuild = data.map(item => {
            const date = parseDate(item.date);
            return {
                x: date ? date.valueOf() : null,
                ...Object.entries(item.commands || {}).reduce((acc, [guildId, commands]) => {
                    acc[getServerName(guildId)] = Object.values(commands).reduce((sum, count) => sum + count, 0);
                    return acc;
                }, {})
            };
        }).filter(item => item.x !== null);

        return {
            moduleEnableByName,
            commandUsageTotal,
            commandUsagePerDay,
            commandUsagePerGuild,
            commandUsagePerDayPerGuild
        };
    };

    const processServerStats = (data) => {
        const serverCountPerDay = data.map(item => {
            const date = parseDate(item.date);
            return date ? {
                x: date.valueOf(),
                y: item.server?.total || 0
            } : null;
        }).filter(item => item !== null);

        const serverAddsRemovesPerDay = data.map(item => {
            const date = parseDate(item.date);
            return date ? {
                x: date.valueOf(),
                adds: item.server?.adds || 0,
                removes: item.server?.removes || 0
            } : null;
        }).filter(item => item !== null);

        // Calculate percent growth
        const lastDay = data[data.length - 1];
        const percentGrowth = lastDay?.server?.total > 0
            ? ((lastDay.server.adds - lastDay.server.removes) / lastDay.server.total) * 100
            : 0;

        // Add inviteSources (this is dummy data as it's not in the analytics prop)
        const inviteSources = [70, 30]; // 70% BotPanel, 30% Other

        return {
            serverCountPerDay,
            serverAddsRemovesPerDay,
            percentGrowth,
            inviteSources
        };
    };

    // Now use these functions to process the data
    const messageAnalyticsData = hasAnalytics ? processMessageAnalytics(analytics) : null;
    const memberAnalyticsData = hasAnalytics ? processMemberAnalytics(analytics) : null;
    const viewsAnalyticsData = hasAnalytics ? processViewsAnalytics(analytics) : null;
    const moduleCommandAnalyticsData = hasAnalytics ? processModuleCommandAnalytics(analytics, modules) : null;
    const serverStatsData = hasAnalytics ? processServerStats(analytics) : null;

    const dummyRevenueData = {
        revenueThisMonth: {
            expected: 123456.78,
            received: 98765.43,
            lastMonth: 112233.44
        },
        todayRevenue: {
            expected: 5678.90,
            received: 4321.09,
            yesterday: 5000.00
        },
        signupsThisMonth: {
            current: 3456,
            lastMonth: 3000
        },
        signupsToday: {
            current: 234,
            yesterday: 200
        }
    };

    const revenueStatsData = subscriptionType === 'free' ? {
        revenueThisMonth: {
            expected: formatCurrency(dummyRevenueData.revenueThisMonth.expected),
            received: formatCurrency(dummyRevenueData.revenueThisMonth.received),
            lastMonth: formatCurrency(dummyRevenueData.revenueThisMonth.lastMonth)
        },
        todayRevenue: {
            expected: formatCurrency(dummyRevenueData.todayRevenue.expected),
            received: formatCurrency(dummyRevenueData.todayRevenue.received),
            yesterday: formatCurrency(dummyRevenueData.todayRevenue.yesterday)
        },
        signupsThisMonth: {
            current: dummyRevenueData.signupsThisMonth.current,
            lastMonth: dummyRevenueData.signupsThisMonth.lastMonth
        },
        signupsToday: {
            current: dummyRevenueData.signupsToday.current,
            yesterday: dummyRevenueData.signupsToday.yesterday
        }
    } : {
        revenueThisMonth: {
            expected: 0,
            received: 0,
            lastMonth: 0
        },
        todayRevenue: {
            expected: 0,
            received: 0,
            yesterday: 0
        },
        signupsThisMonth: {
            current: 0,
            lastMonth: 0
        },
        signupsToday: {
            current: 0,
            yesterday: 0
        }
    };

    // Helper function to generate dates
    const generateDates = (startDate, days) => {
        let dates = [];
        for (let i = 0; i < days; i++) {
            let date = new Date(startDate);
            date.setDate(startDate.getDate() + i);
            dates.push(date.toISOString().split('T')[0]);
        }
        return dates;
    };

    // Generate 3 months of dummy data
    const startDate = new Date('2023-06-01');
    const dates = generateDates(startDate, 90);

    // Define a set of pretty colors
    const prettyColors = [
        '#FF6B6B', // Coral Red
        '#4ECDC4', // Caribbean Green
        '#45B7D1', // Sky Blue
        '#FFA07A', // Light Salmon
        '#98D8C8', // Seafoam Green
        '#F7B801', // Amber
        '#7FDBFF', // Azure
        '#FF85A2', // Flamingo Pink
        '#9B59B6', // Amethyst
        '#3D9970', // Olive Green
    ];

    const getCommonChartOptions = () => ({
        chart: {
            background: '#1e293b', // Darker background color
            foreColor: '#cbd5e1', // Light text color for better contrast
        },
        grid: {
            borderColor: '#334155',
        },
        legend: {
            labels: {
                colors: '#cbd5e1',
            },
        },
        tooltip: {
            theme: 'dark',
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#cbd5e1',
                },
            },
            axisBorder: {
                color: '#475569',
            },
            axisTicks: {
                color: '#475569',
            },
        },
    });

    const getAreaChartOptions = (title) => ({
        ...getCommonChartOptions(),
        chart: {
            ...getCommonChartOptions().chart,
            type: 'area',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        xaxis: {
            type: 'datetime',
            labels: {
                formatter: function (val) {
                    return moment(val).format('MMM DD');
                },
                style: {
                    colors: '#cbd5e1',
                },
            },
        },
        yaxis: {
            title: {
                text: title,
                style: {
                    color: '#cbd5e1',
                },
            },
            labels: {
                style: {
                    colors: '#cbd5e1',
                },
            },
        },
        colors: [prettyColors[0]], // Use the first color from prettyColors
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.2,
                stops: [0, 90, 100],
            },
        },
    });

    const getBarChartOptions = (title) => ({
        ...getCommonChartOptions(),
        chart: {
            ...getCommonChartOptions().chart,
            type: 'bar',
            height: 350,
            toolbar: { show: false },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        xaxis: {
            categories: moduleCommandAnalyticsData.moduleEnableByName.map(item => item.name),
        },
        yaxis: {
            title: { text: title },
        },
        colors: ['#3B82F6', '#10B981'], // Blue for enables, Green for disables
    });

    const getPieChartOptions = () => ({
        ...getCommonChartOptions(),
        chart: {
            ...getCommonChartOptions().chart,
            type: 'pie',
            height: 350,
        },
        labels: moduleCommandAnalyticsData.commandUsagePerGuild.map(item => item.guild),
        responsive: [{
            breakpoint: 480,
            options: {
                chart: { width: 200 },
                legend: { position: 'bottom' }
            }
        }],
        colors: ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6', '#EC4899', '#14B8A6', '#F97316', '#6366F1', '#84CC16'], // Tailwind colors
    });

    // Update the getStackedBarChartOptions function to accept a custom Y-axis title
    const getStackedBarChartOptions = (yAxisTitle = 'Count') => ({
        ...getCommonChartOptions(),
        chart: {
            ...getCommonChartOptions().chart,
            type: 'bar',
            height: 350,
            stacked: true,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            type: 'datetime',
            labels: {
                formatter: function (val) {
                    return moment(val).format('MMM DD');
                },
            },
        },
        yaxis: {
            title: {
                text: yAxisTitle,
                style: {
                    color: '#cbd5e1',
                },
            },
        },
        fill: {
            opacity: 1
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
        },
        colors: ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6', '#EC4899', '#14B8A6', '#F97316', '#6366F1', '#84CC16'], // Tailwind colors
    });

    const getServerStatsOptions = () => ({
        ...getCommonChartOptions(),
        chart: {
            ...getCommonChartOptions().chart,
            type: 'line',
            height: 350,
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        yaxis: {
            title: {
                text: 'Server Count',
                style: {
                    color: '#cbd5e1',
                },
            },
        },
        colors: [color_settings.primary],
    });

    const getServerGrowthOptions = () => ({
        ...getCommonChartOptions(),
        chart: {
            ...getCommonChartOptions().chart,
            type: 'bar',
            height: 350,
            stacked: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: '#cbd5e1',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Servers',
                style: {
                    color: '#cbd5e1',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        colors: [color_settings.primary, '#00E396'],
    });

    const StatCard = ({ title, value, colorClass, data }) => {
        const sparklineOptions = {
            chart: {
                type: 'line',
                sparkline: {
                    enabled: true
                },
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            tooltip: {
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter: function (seriesName) {
                            return '';
                        }
                    }
                },
                marker: {
                    show: false
                }
            },
            colors: ['#ffffff'],
        };

        return (
            <div className={`${colorClass} p-4 rounded-lg shadow-md relative overflow-hidden`}>
                <div className="relative z-10">
                    <h3 className="text-gray-100 text-sm font-semibold uppercase">{title}</h3>
                    <p className="text-white text-3xl font-bold mt-2">{value}</p>
                </div>
                <div className="absolute bottom-0 right-0 w-2/3 h-16 opacity-50">
                    <ReactApexChart options={sparklineOptions} series={[{ data: data }]} type="line" height={60} />
                </div>
            </div>
        );
    };

    // Dummy data for sparklines
    const sparklineData = {
        servers: [1100, 1200, 1150, 1300, 1200, 1300, 1213],
        members: [400, 410, 405, 415, 420, 418, 422],
        commands: [280, 290, 300, 305, 308, 310, 311],
        views: [15, 17, 19, 20, 21, 22, 22]
    };

    const renderSection = (sectionId) => {
        if (!hasAnalytics) {
            return (
                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                    <p className="text-white">No analytics data available.</p>
                </div>
            );
        }

        switch (sectionId) {
            case 'topStats':
                return <OverviewStatsSection />;
            case 'messageAnalytics':
                return (
                    <section>
                        <h2 className="text-2xl font-bold mb-4 text-white">Message Analytics</h2>
                        {messageAnalyticsData ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Messages Received Total</h3>
                                    <ReactApexChart
                                        options={getAreaChartOptions('Messages')}
                                        series={[{ name: 'Messages', data: messageAnalyticsData.messagesReceivedTotal }]}
                                        type="area"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Messages Received by Guild</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getPieChartOptions(),
                                            labels: messageAnalyticsData.messagesReceivedByGuild.map(item => item.guild)
                                        }}
                                        series={messageAnalyticsData.messagesReceivedByGuild.map(item => item.messages)}
                                        type="pie"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg col-span-2">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Messages Received Per Day</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getStackedBarChartOptions('Messages'),
                                            xaxis: {
                                                ...getStackedBarChartOptions('Messages').xaxis,
                                                categories: messageAnalyticsData.messagesReceivedPerDay.map(item => moment(item.x).format('MMM DD')),
                                            },
                                        }}
                                        series={Object.keys(messageAnalyticsData.messagesReceivedPerDay[0])
                                            .filter(key => key !== 'x')
                                            .map(serverName => ({
                                                name: serverName,
                                                data: messageAnalyticsData.messagesReceivedPerDay.map(item => item[serverName] || 0)
                                            }))}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        ) : (
                            <p className="text-white">No message analytics data available.</p>
                        )}
                    </section>
                );
            case 'memberAnalytics':
                return (
                    <section>
                        <h2 className="text-2xl font-bold mb-4 text-white">Member Analytics</h2>
                        {memberAnalyticsData ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Members Handled Total for Period</h3>
                                    <ReactApexChart
                                        options={getAreaChartOptions('Members Handled')}
                                        series={[{ name: 'Members Handled', data: memberAnalyticsData.membersHandledTotal }]}
                                        type="area"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Members Handled Via Guild</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getPieChartOptions(),
                                            labels: memberAnalyticsData.membersHandledByGuild.map(item => item.guild)
                                        }}
                                        series={memberAnalyticsData.membersHandledByGuild.map(item => item.members)}
                                        type="pie"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg col-span-2">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Members Handled Per Day for Period</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getStackedBarChartOptions('Members'),
                                            xaxis: {
                                                ...getStackedBarChartOptions('Members').xaxis,
                                                categories: memberAnalyticsData.membersHandledPerDay.map(item => item.date)
                                            }
                                        }}
                                        series={Object.keys(memberAnalyticsData.membersHandledPerDay[0])
                                            .filter(key => key !== 'date')
                                            .map(guildId => ({
                                                name: guildId,
                                                data: memberAnalyticsData.membersHandledPerDay.map(item => item[guildId] || 0)
                                            }))}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        ) : (
                            <p className="text-white">No member analytics data available.</p>
                        )}
                    </section>
                );
            case 'viewsAnalytics':
                return (
                    <section>
                        <h2 className="text-2xl font-bold mb-4 text-white">Views Analytics</h2>
                        {viewsAnalyticsData ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Views Total for Period</h3>
                                    <ReactApexChart
                                        options={getAreaChartOptions('Views')}
                                        series={[{ name: 'Views', data: viewsAnalyticsData.viewsTotalForPeriod }]}
                                        type="area"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Views per Route</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getPieChartOptions(),
                                            labels: viewsAnalyticsData.viewsPerRoute.map(item => item.route)
                                        }}
                                        series={viewsAnalyticsData.viewsPerRoute.map(item => item.views)}
                                        type="pie"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg col-span-2">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Views Per Day for Period (Per Route)</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getStackedBarChartOptions('Views'),
                                            xaxis: {
                                                ...getStackedBarChartOptions('Views').xaxis,
                                                type: 'datetime',
                                            }
                                        }}
                                        series={Object.keys(viewsAnalyticsData.viewsPerDayPerRoute[0])
                                            .filter(key => key !== 'x')
                                            .map(route => ({
                                                name: route,
                                                data: viewsAnalyticsData.viewsPerDayPerRoute.map(item => ({
                                                    x: item.x,
                                                    y: item[route] || 0
                                                }))
                                            }))}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                                {/* Views per Day per Server and Views per Server on the same row */}
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Views per Day per Server</h3>
                                    {viewsAnalyticsData.viewsPerDayPerServer.length > 0 ? (
                                        <ReactApexChart
                                            options={{
                                                ...getStackedBarChartOptions('Views'),
                                                xaxis: {
                                                    ...getStackedBarChartOptions('Views').xaxis,
                                                    type: 'datetime',
                                                }
                                            }}
                                            series={Object.keys(viewsAnalyticsData.viewsPerDayPerServer[0] || {})
                                                .filter(key => key !== 'x')
                                                .map(serverName => ({
                                                    name: serverName,
                                                    data: viewsAnalyticsData.viewsPerDayPerServer.map(item => ({
                                                        x: item.x,
                                                        y: parseFloat(item[serverName]) || 0
                                                    }))
                                                }))}
                                            type="bar"
                                            height={350}
                                        />
                                    ) : (
                                        <p className="text-white">No data available for Views per Day per Server</p>
                                    )}
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Views per Server</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getPieChartOptions(),
                                            labels: viewsAnalyticsData.viewsPerServer.map(item => item.server)
                                        }}
                                        series={viewsAnalyticsData.viewsPerServer.map(item => item.views)}
                                        type="pie"
                                        height={350}
                                    />
                                </div>
                            </div>
                        ) : (
                            <p className="text-white">No views analytics data available.</p>
                        )}
                    </section>
                );
            case 'moduleCommandAnalytics':
                return (
                    <section>
                        <h2 className="text-2xl font-bold mb-4 text-white">Module & Command Analytics</h2>
                        {moduleCommandAnalyticsData ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Module Enable by Module Name</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getBarChartOptions('Enable Count'),
                                            xaxis: {
                                                categories: moduleCommandAnalyticsData.moduleEnableByName.map(item => item.name)
                                            }
                                        }}
                                        series={[
                                            {
                                                name: 'Enables',
                                                data: moduleCommandAnalyticsData.moduleEnableByName.map(item => item.enabled)
                                            },
                                            {
                                                name: 'Disables',
                                                data: moduleCommandAnalyticsData.moduleEnableByName.map(item => item.disabled)
                                            }
                                        ]}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Command Usage Total for Period</h3>
                                    <ReactApexChart
                                        options={getAreaChartOptions('Commands')}
                                        series={[{ name: 'Command Usage', data: moduleCommandAnalyticsData.commandUsageTotal }]}
                                        type="area"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg col-span-2">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Command Usage Per Day for Period</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getStackedBarChartOptions('Command Usage'),
                                            xaxis: {
                                                ...getStackedBarChartOptions('Command Usage').xaxis,
                                                categories: moduleCommandAnalyticsData.commandUsagePerDay.map(item => item.date)
                                            }
                                        }}
                                        series={Object.keys(moduleCommandAnalyticsData.commandUsagePerDay[0])
                                            .filter(key => key !== 'date')
                                            .map(commandName => ({
                                                name: commandName,
                                                data: moduleCommandAnalyticsData.commandUsagePerDay.map(item => item[commandName] || 0)
                                            }))}
                                        type="bar"
                                        height={350}
                                    />
                                </div>

                                {/* Command Usage Per Guild and Command Usage Per Day per Guild on the same row */}
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Command Usage Per Guild</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getPieChartOptions(),
                                            labels: moduleCommandAnalyticsData.commandUsagePerGuild.map(item => item.guild)
                                        }}
                                        series={moduleCommandAnalyticsData.commandUsagePerGuild.map(item => item.usage)}
                                        type="pie"
                                        height={350}
                                    />
                                </div>

                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Command Usage Per Day per Guild</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getStackedBarChartOptions('Command Usage'),
                                            xaxis: {
                                                ...getStackedBarChartOptions('Command Usage').xaxis,
                                                type: 'datetime'
                                            }
                                        }}
                                        series={Object.keys(moduleCommandAnalyticsData.commandUsagePerDayPerGuild[0])
                                            .filter(key => key !== 'x')
                                            .map(guildName => ({
                                                name: guildName,
                                                data: moduleCommandAnalyticsData.commandUsagePerDayPerGuild.map(item => ({
                                                    x: item.x,
                                                    y: item[guildName] || 0
                                                }))
                                            }))}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        ) : (
                            <p className="text-white">No module and command analytics data available.</p>
                        )}
                    </section>
                );
            case 'serverStats':
                return (
                    <section>
                        <h2 className="text-2xl font-bold mb-4 text-white">Server Stats</h2>
                        {serverStatsData ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Current Server Count Per Day</h3>
                                    <ReactApexChart
                                        options={{
                                            ...getServerStatsOptions(),
                                            xaxis: {
                                                ...getServerStatsOptions().xaxis,
                                                type: 'datetime'
                                            }
                                        }}
                                        series={[{ name: 'Server Count', data: serverStatsData.serverCountPerDay }]}
                                        type="line"
                                        height={350}
                                    />
                                </div>
                                <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
                                    <h3 className="text-xl font-semibold mb-4 text-white">Server Adds & Removes per Day</h3>
                                    <ReactApexChart
                                        options={getServerGrowthOptions()}
                                        series={[
                                            { name: 'Adds', data: serverStatsData.serverAddsRemovesPerDay.map(item => ({ x: item.x, y: item.adds })) },
                                            { name: 'Removes', data: serverStatsData.serverAddsRemovesPerDay.map(item => ({ x: item.x, y: item.removes })) }
                                        ]}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        ) : (
                            <p className="text-white">No server stats data available.</p>
                        )}
                    </section>
                );
            default:
                return null;
        }
    };

    return (
        <Dashboard>
            <PremiumModal requiredType="basic">
                {bot?.subscription?.type == "free" && <TestDataWatermark />}
                <div className="flex w-full flex-wrap items-end mb-6 justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                    <div className="flex items-center gap-2">
                        <Heading>Analytics</Heading>
                        <PremiumBadge type="basic" />
                    </div>
                </div>

                <div className="flex items-center justify-between mb-6">
                    <h1 className="text-2xl font-bold text-white">Analytics Overview</h1>
                    <div className="flex items-center space-x-4">
                        <Button
                            onClick={() => setIsSettingsOpen(true)}
                            className="flex items-center"
                            size="sm"
                        >
                            <Cog6ToothIcon className="w-5 h-5 mr-2" />
                            Settings
                        </Button>
                    </div>
                </div>
                <OverviewStatsSection placeholder={subscriptionType === 'free'} />

                <div className="flex flex-col gap-8 mt-5">
                    {sections.filter(section => section.visible).map(section => renderSection(section.id))}
                </div>
                <SettingsModal
                    isOpen={isSettingsOpen}
                    onClose={() => setIsSettingsOpen(false)}
                    sections={sections}
                    onSave={(newSections) => {
                        setSections(newSections);
                        setIsSettingsOpen(false);
                    }}
                />
            </PremiumModal>
        </Dashboard>
    );
};

const mapStateToProps = (state) => ({
    color_settings: state.data.bot.color_settings,
    modules: state.data.modules,
    analytics: state.data.analytics,
    isPro: state.data.bot.is_pro,
    servers: state.data.servers,
    bot: state.data.bot,
});

export default connect(mapStateToProps)(Analytics);