import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

class ButtonSettings extends Component {
    handleButtonChange = (index, field, value) => {
        const updatedButtons = [...this.props.buttons];
        updatedButtons[index] = { ...updatedButtons[index], [field]: value };
        this.props.onButtonsChange(updatedButtons);
    };

    addButton = () => {
        if (this.props.buttons.length < 2) {
            const newButton = { link: '', text: '', icon: faArrowRight, type: 'primary' };
            this.props.onButtonsChange([...this.props.buttons, newButton]);
        }
    };

    removeButton = (index) => {
        const updatedButtons = this.props.buttons.filter((_, i) => i !== index);
        this.props.onButtonsChange(updatedButtons);
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Buttons</Label>
                        <Description>Add up to two buttons</Description>
                        {this.props.buttons.map((button, index) => (
                            <div key={index} className="mt-6 p-6 bg-gray-800 rounded-lg shadow-md">
                                <Field>
                                    <Label>Button Text</Label>
                                    <Input
                                        value={button.text}
                                        onChange={(e) => this.handleButtonChange(index, 'text', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-4">
                                    <Label>Button Link</Label>
                                    <Input
                                        value={button.link}
                                        onChange={(e) => this.handleButtonChange(index, 'link', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-4">
                                    <Label>Button Type</Label>
                                    <Select
                                        value={button.type}
                                        onChange={(e) => this.handleButtonChange(index, 'type', e.target.value)}
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    >
                                        <option value="primary">Primary</option>
                                        <option value="secondary">Secondary</option>
                                        <option value="success">Success</option>
                                        <option value="danger">Danger</option>
                                    </Select>
                                </Field>
                                <Button onClick={() => this.removeButton(index)} className="mt-6">Remove Button</Button>
                            </div>
                        ))}
                        {this.props.buttons.length < 2 && (
                            <Button onClick={this.addButton} className="mt-6">Add Button</Button>
                        )}
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default ButtonSettings;
