import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Textarea } from '../../components/textarea';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import ButtonSettings from './ButtonSettings';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';

class FeaturesSettings extends Component {
    state = {
        layout: 1,
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        items: []
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (field, value) => {
        console.log(field, value);
        this.setState({ [field]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleFeatureChange = (index, field, value) => {
        this.setState(prevState => {
            const updatedItems = [...prevState.items];
            updatedItems[index] = { ...updatedItems[index], [field]: value };
            return { items: updatedItems };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addFeature = () => {
        this.setState(prevState => ({
            items: [...prevState.items, { name: '', description: '', image: { src: '', caption: '' }, buttons: [] }]
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeFeature = (index) => {
        this.setState(prevState => ({
            items: prevState.items.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your features section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Alternating Layout</option>
                            <option value={2}>Grid Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Features</Label>
                        <Description>Add or edit individual features</Description>
                        {this.state.items.map((feature, featureIndex) => (
                            <div key={featureIndex} className="mt-6 p-6 border border-gray-700 rounded-lg shadow-md">
                                <Field>
                                    <Label>Feature Name</Label>
                                    <Input
                                        value={feature.name || ''}
                                        onChange={(e) => this.handleFeatureChange(featureIndex, 'name', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-4">
                                    <Label>Feature Description</Label>
                                    <Textarea
                                        value={feature.description || ''}
                                        onChange={(e) => this.handleFeatureChange(featureIndex, 'description', e.target.value)}
                                        rows={4}
                                    />
                                </Field>
                                <Field className="mt-4">
                                    <Label>Feature Image URL</Label>
                                    <Input
                                        value={feature.image?.src || ''}
                                        onChange={(e) => this.handleFeatureChange(featureIndex, 'image', { ...feature.image, src: e.target.value })}
                                    />
                                </Field>
                                <Field className="mt-4">
                                    <Label>Feature Image Caption</Label>
                                    <Input
                                        value={feature.image?.caption || ''}
                                        onChange={(e) => this.handleFeatureChange(featureIndex, 'image', { ...feature.image, caption: e.target.value })}
                                    />
                                </Field>
                                <ButtonSettings
                                    buttons={feature.buttons || []}
                                    onButtonsChange={(updatedButtons) => {
                                        this.handleFeatureChange(featureIndex, 'buttons', updatedButtons);
                                    }}
                                />
                                <Button onClick={() => this.removeFeature(featureIndex)} className="mt-6">Remove Feature</Button>
                            </div>
                        ))}
                        <Button onClick={this.addFeature} className="mt-6">Add Feature</Button>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default FeaturesSettings;
