import React from 'react';

const TestDataWatermark = () => (
    <div className="fixed inset-0 flex items-center justify-center pointer-events-none z-50">
        <div className="transform rotate-45 text-red-500 text-6xl font-bold opacity-20">
            Example Data
        </div>
    </div>
);

export default TestDataWatermark;
