import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge } from './badge';

const tiers = [
    { name: 'Free', id: 'free', color: 'gray' },
    { name: 'Basic', id: 'basic', color: 'blue' },
    { name: 'Hobby', id: 'hobby', color: 'green' },
    { name: 'Professional', id: 'pro', color: 'purple' }
];

export class PremiumBadge extends Component {
    render() {
        const { type } = this.props;
        const { subscription } = this.props.bot;
        const currentTierIndex = tiers.findIndex(tier => tier.id === subscription.type);
        const badgeTierIndex = tiers.findIndex(tier => tier.id === type);

        if (badgeTierIndex <= currentTierIndex) {
            return null;
        }

        const badgeTier = tiers[badgeTierIndex];

        return (
            <Badge color={badgeTier.color} className={"font-semibold"}>
                <img src={`https://botpanel.gg/img/logo.png`} alt={badgeTier.name} className="h-4" />
                {badgeTier.name}
            </Badge>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot || {
        subscription: {
            type: 'free'
        }
    }
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumBadge);
