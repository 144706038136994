import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export const AccordionItem = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = (e) => {
        e.preventDefault(); // Prevent form submission
        setIsOpen(!isOpen);
    };

    return (
        <div className="border-b border-gray-700 last:border-b-0">
            <button
                className={`flex justify-between items-center w-full py-4 px-6 text-left text-white hover:bg-blue-600 transition-colors duration-200 ${isOpen ? 'bg-blue-600' : ''}`}
                onClick={toggleAccordion}
            >
                <span className="text-lg font-medium">{title}</span>
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="text-gray-400" />
            </button>
            {isOpen && <div className="p-6 bg-gray-900">{children}</div>}
        </div>
    );
};

export const Accordion = ({ children }) => {
    return <div className="border border-gray-700 rounded-lg overflow-hidden">{children}</div>;
};
