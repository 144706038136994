import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import { Textarea } from '../../components/textarea';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';

class ReviewsSettings extends Component {
    state = {
        layout: 1,
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        reviews: []
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleReviewChange = (index, field, value) => {
        this.setState(prevState => {
            const updatedReviews = [...prevState.reviews];
            updatedReviews[index] = { ...updatedReviews[index], [field]: value };
            return { reviews: updatedReviews };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addReview = () => {
        this.setState(prevState => ({
            reviews: [...prevState.reviews, { author: '', content: '', rating: 5 }]
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeReview = (index) => {
        this.setState(prevState => ({
            reviews: prevState.reviews.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your reviews section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Grid Layout</option>
                            <option value={2}>Carousel Layout</option>
                            <option value={3}>List Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Reviews</Label>
                        <Description>Add or edit reviews</Description>
                        {this.state.reviews.map((review, index) => (
                            <div key={index} className="mt-4 p-4 border border-gray-300 rounded-md">
                                <Field>
                                    <Label>Author</Label>
                                    <Input
                                        value={review.author || ''}
                                        onChange={(e) => this.handleReviewChange(index, 'author', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Content</Label>
                                    <Textarea
                                        value={review.content || ''}
                                        onChange={(e) => this.handleReviewChange(index, 'content', e.target.value)}
                                        rows={3}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Rating</Label>
                                    <Input
                                        type="number"
                                        min="1"
                                        max="5"
                                        value={review.rating || 5}
                                        onChange={(e) => this.handleReviewChange(index, 'rating', parseInt(e.target.value))}
                                    />
                                </Field>
                                <Button onClick={() => this.removeReview(index)} className="mt-4">Remove Review</Button>
                            </div>
                        ))}
                        <Button onClick={this.addReview} className="mt-4">Add Review</Button>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default ReviewsSettings;
