import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';

class AlertSettings extends Component {
    state = {
        layout: 1,
        type: 'warning',
        title: '',
        text: '',
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left'
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your alert</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Default Layout</option>
                            <option value={2}>Icon Left Layout</option>
                            <option value={3}>Centered Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Alert Type</Label>
                        <Description>Choose the type of alert</Description>
                        <Select
                            name="type"
                            value={this.state.type}
                            onChange={(e) => this.handleChange('type', e.target.value)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value="warning">Warning</option>
                            <option value="error">Error</option>
                            <option value="info">Info</option>
                            <option value="success">Success</option>
                        </Select>
                    </Field>

                    <Field>
                        <Label>Title</Label>
                        <Description>The title for your alert</Description>
                        <Input
                            name="title"
                            value={this.state.title}
                            onChange={(e) => this.handleChange('title', e.target.value)}
                            placeholder="Alert Title"
                        />
                    </Field>

                    <Field>
                        <Label>Text</Label>
                        <Description>The main text for your alert</Description>
                        <Input
                            name="text"
                            value={this.state.text}
                            onChange={(e) => this.handleChange('text', e.target.value)}
                            placeholder="Alert message"
                        />
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default AlertSettings;
