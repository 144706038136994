import React, { Component } from 'react';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';

class ScrollToTopSettings extends Component {
    state = {
        position: 'bottom-right'
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    {/* DisplayHeader is not used in ScrollToTopSettings as it's a utility component without a header */}
                    <Field>
                        <Label>Position</Label>
                        <Description>Choose the position for the scroll to top button</Description>
                        <Select
                            name="position"
                            value={this.state.position}
                            onChange={this.handleChange}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value="bottom-left">Bottom Left</option>
                            <option value="bottom-right">Bottom Right</option>
                            <option value="top-left">Top Left</option>
                            <option value="top-right">Top Right</option>
                            <option value="bottom-middle">Bottom Middle</option>
                        </Select>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default ScrollToTopSettings;
