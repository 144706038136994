import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dashboard from './Dashboard';
import { Heading } from '../components/heading';
import { Button } from '../components/button';
import { PlusIcon, EyeIcon, TrashIcon, ChevronDownIcon, ChevronUpIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../components/dialog';
import { Select } from '../components/select';
import { updateBotSettings } from '../actions';
import { Link } from 'react-router-dom';
import PremiumBadge from '../components/PremiumBadge';
import PremiumModal from '../components/PremiumModal';

import {
    HeaderSettings,
    FeaturesSettings,
    FAQsSettings,
    ServersSettings,
    TextSettings,
    YoutubeSettings,
    ImageSettings,
    CommandsSettings,
    CommentsSettings,
    CTASettings,
    NewsSettings,
    PricingSettings,
    ReviewsSettings,
    StatisticsSettings,
    TeamSettings,
    ContactSettings,
    TestimonialsSettings,
    AlertSettings,
    ScrollToTopSettings,
    GapSettings
} from './home_settings';

const sectionTypes = [
    'header', 'features', 'faqs', 'servers', 'text', 'youtube', 'image', 'commands', 'cta',
    'statistics', 'team', 'testimonials', 'alert', 'scrollToTop', 'gap'
];

class HomeBuilder extends Component {
    state = {
        sections: [],
        isAddDialogOpen: false,
        newSectionType: sectionTypes[0],
        isLoading: true
    };

    componentDidMount() {
        if (this.props.home_components) {
            this.setState({
                sections: this.props.home_components,
                isLoading: false
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.home_components !== this.props.home_components) {
            this.setState({
                sections: this.props.home_components,
                isLoading: false
            });
        }
    }

    onDragEnd = (result) => {
        if (!result.destination) return;

        const newSections = Array.from(this.state.sections);
        const [reorderedItem] = newSections.splice(result.source.index, 1);
        newSections.splice(result.destination.index, 0, reorderedItem);

        this.setState({ sections: newSections }, this.saveComponents);
    };

    addSection = () => {
        const newId = `section${Date.now()}`;  // Generate a unique ID using timestamp
        const newSection = {
            id: newId,
            type: this.state.newSectionType,
            content: `${this.state.newSectionType.charAt(0).toUpperCase() + this.state.newSectionType.slice(1)} Section`,
            expanded: false,
            options: this.state.newSectionType === 'features' ? { features: [] } : {},
        };
        this.setState(prevState => ({
            sections: [...prevState.sections, newSection],
            isAddDialogOpen: false
        }), this.saveComponents);
    };

    toggleExpand = (id) => {
        this.setState(prevState => ({
            sections: prevState.sections.map(section =>
                section.id === id ? { ...section, expanded: !section.expanded } : section
            )
        }));
    };

    deleteSection = (id) => {
        this.setState(prevState => ({
            sections: prevState.sections.filter(section => section.id !== id)
        }), this.saveComponents);
    };

    updateSectionOptions = (id, newOptions) => {
        this.setState(prevState => ({
            sections: prevState.sections.map(section =>
                section.id === id ? { ...section, options: newOptions } : section
            )
        }), this.saveComponents);
    };

    saveComponents = () => {
        this.props.updateBotSettings('home_components', this.state.sections);
    };

    renderSectionSettings = (section) => {
        switch (section.type) {
            case 'header':
                return <HeaderSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'features':
                return <FeaturesSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'faqs':
                return <FAQsSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'servers':
                return <ServersSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'text':
                return <TextSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'youtube':
                return <YoutubeSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'image':
                return <ImageSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'commands':
                return <CommandsSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'comments':
                return <CommentsSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'cta':
                return <CTASettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'news':
                return <NewsSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'pricing':
                return <PricingSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'reviews':
                return <ReviewsSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'statistics':
                return <StatisticsSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'team':
                return <TeamSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'contact':
                return <ContactSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'testimonials':
                return <TestimonialsSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'alert':
                return <AlertSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'scrollToTop':
                return <ScrollToTopSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            case 'gap':
                return <GapSettings options={section.options} updateOptions={(newOptions) => this.updateSectionOptions(section.id, newOptions)} />;
            default:
                return <p className="text-sm text-zinc-600 dark:text-zinc-300">Settings for {section.type} are not available yet.</p>;
        }
    };

    renderLinkURL = () => {
        if (this.props.bot.dns.custom && this.props.bot.dns.status == 'active') {
            return `https://${this.props.bot.dns.url}`;
        } else {
            return `https://${this.props.bot.id}.${process.env.REACT_APP_ROOT_NAME}`;
        }
    };

    render() {
        if (this.state.isLoading) {
            return (
                <Dashboard>
                    <div className="flex items-center justify-center h-screen">
                        {/* <Spinner size="large" /> */}
                    </div>
                </Dashboard>
            );
        }

        const { homeType } = this.props.home_settings;

        if (homeType !== 'advanced') {
            return (
                <Dashboard>
                    <div className="flex w-full flex-wrap items-end mb-6 justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                        <div className="flex items-center gap-2">
                            <Heading>Home Page Builder</Heading>
                            <PremiumBadge type="hobby" />
                        </div>

                    </div>
                    <div className="mt-8 p-4 bg-yellow-100 dark:bg-yellow-900 border-l-4 border-yellow-500 text-yellow-700 dark:text-yellow-200 rounded-md shadow-md">
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <h3 className="text-sm font-medium">Advanced Home Builder Not Enabled</h3>
                                <div className="mt-2 text-sm">
                                    <p>
                                        The Home Builder is only available when the home page mode is set to "Advanced".
                                        Please go to the <Link to="/dashboard/home" className="font-medium underline hover:text-yellow-600 dark:hover:text-yellow-300">
                                            Home Settings page</Link> and change the Home Page Mode to "Advanced Home (Builder)" to use this feature.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dashboard>
            );
        }

        return (
            <Dashboard>
                <PremiumModal requiredType="hobby">
                    <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                        <div className='flex items-center gap-2'>
                            <Heading>Home Page Settings</Heading>
                            <PremiumBadge type="hobby" />
                        </div>

                        <div className="flex gap-4">
                            <Button href={this.renderLinkURL()} className="ml-auto">
                                <EyeIcon className="h-5 w-5 mr-2" />
                                View Homepage
                            </Button>
                        </div>
                    </div>

                    <div className="mt-8">
                        <div className="flex items-center justify-between mb-6">
                            <Heading as="h2" className="text-2xl font-bold">Page Builder</Heading>
                            <Button onClick={() => this.setState({ isAddDialogOpen: true })}>
                                <PlusIcon className="h-5 w-5 mr-2" />
                                Add New Section
                            </Button>
                        </div>
                        <Dialog open={this.state.isAddDialogOpen} onClose={() => this.setState({ isAddDialogOpen: false })}>
                            <DialogTitle>Add New Section</DialogTitle>
                            <DialogBody>
                                <DialogDescription>Choose a section type to add</DialogDescription>
                                <Select
                                    value={this.state.newSectionType}
                                    onChange={(e) => this.setState({ newSectionType: e.target.value })}
                                    className="w-full mt-2"
                                >
                                    {sectionTypes.map((type) => (
                                        <option key={type} value={type}>
                                            {type.charAt(0).toUpperCase() + type.slice(1)}
                                        </option>
                                    ))}
                                </Select>
                            </DialogBody>
                            <DialogActions>
                                <Button onClick={() => this.setState({ isAddDialogOpen: false })}>Cancel</Button>
                                <Button onClick={this.addSection}>Add Section</Button>
                            </DialogActions>
                        </Dialog>
                        <div className="mt-4">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="sections">
                                    {(provided) => (
                                        <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-3">
                                            {this.state.sections.map((section, index) => (
                                                <Draggable key={section.id} draggableId={section.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <li
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className={`rounded-lg border ${snapshot.isDragging
                                                                ? 'bg-zinc-100 dark:bg-zinc-700 border-zinc-300 dark:border-zinc-600 shadow-lg'
                                                                : 'bg-white dark:bg-zinc-800 border-zinc-200 dark:border-zinc-700'
                                                                } transition-all duration-200 overflow-hidden`}
                                                        >
                                                            <div
                                                                {...provided.dragHandleProps}
                                                                className="flex items-center justify-between p-4 cursor-pointer hover:bg-zinc-50/50 dark:hover:bg-zinc-700/50"
                                                                onClick={() => this.toggleExpand(section.id)}
                                                            >
                                                                <div className="flex items-center">
                                                                    <span className="mr-3 text-zinc-400 dark:text-zinc-500">☰</span>
                                                                    <span className="text-zinc-800 dark:text-zinc-200 font-medium">{section.content}</span>
                                                                </div>
                                                                <div className="flex items-center space-x-2">
                                                                    <Button
                                                                        size="sm"
                                                                        variant="ghost"
                                                                        className="text-zinc-500 hover:text-red-500 dark:text-zinc-400 dark:hover:text-red-400"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            console.log(section, 'SECTION');
                                                                            this.deleteSection(section.id);
                                                                        }}
                                                                    >
                                                                        <TrashIcon className="h-4 w-4" />
                                                                    </Button>
                                                                    {section.expanded ? <ChevronUpIcon className="h-5 w-5 text-zinc-400" /> : <ChevronDownIcon className="h-5 w-5 text-zinc-400" />}
                                                                </div>
                                                            </div>
                                                            {section.expanded && (
                                                                <div className="p-4 bg-zinc-100 dark:bg-zinc-800 border-t border-zinc-200 dark:border-zinc-700 transition-all duration-200">
                                                                    {this.renderSectionSettings(section)}
                                                                </div>
                                                            )}
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                </PremiumModal>
            </Dashboard>
        );
    }
}

const mapStateToProps = (state) => ({
    home_components: state.data.bot.home_components ? state.data.bot.home_components : [],
    home_settings: state.data.bot.home_settings ? state.data.bot.home_settings : {},
    bot: state.data.bot
});

const mapDispatchToProps = {
    updateBotSettings
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeBuilder);
