import update from "immutability-helper";
var INIT_STATE = {
  loaded: false,
  user: null,
  bot: {
    dash_settings: {},
    color_settings: {},
    invite_settings: {},
    home_settings: {},
    home_components: [],
    module_settings: {},
    dns: {
      url: "",
      txtRecords: {}
    },
  },
  bots: [],
  saves_required: {
    bot: null,
  },
  modules: [],
  stats: {
    pageviews: 0,
    server_count: 0
  },
  analytics: [],
};

var dataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        loaded: true,
        user: action.payload.user,
        bot: {
          ...action.payload.bot,
          module_settings: action.payload.bot.module_settings || {},
          home_components: action.payload.bot.home_components || [],
          home_settings: action.payload.bot.home_settings || {},
        },
        bots: action.payload.bots,
        servers: action.payload.servers,
        modules: action.payload.modules,
        stats: action.payload.stats,
        analytics: action.payload.analytics,
      };

    case "UPDATE_BOT_SETTINGS":
      // Use the immutability-helper library to update the bot settings
      return update(state, {
        bot: { $set: action.payload },
        saves_required: { bot: { $set: Date.now() } },
      });
    case "UPDATE_BOT_SETTINGS_NO_SAVE":
      // Use the immutability-helper library to update the bot settings
      return update(state, {
        bot: { $set: action.payload },
        // saves_required: { bot: { $set: Date.now() } },
      });
    case "UPDATE_BOT_SAVE_REQUIRED":
      return update(state, {
        bot: { bot: { $set: null } },
      });
    case "SET_LOADING":
      return {
        ...state,
        loaded: action.payload,
      };



    default:
      return state;
  }
};

export default dataReducer;
