import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getData, setAuth, updateBotSaveRequired, setLoading } from '../actions';
import { Description, ErrorMessage, Field, FieldGroup, Fieldset, Label, Legend } from '../components/fieldset';
import { Input } from '../components/input';
import { Select } from '../components/select';
import { Text } from '../components/text';
import { Textarea } from '../components/textarea';
import { Divider } from '../components/divider';
import { Button } from '../components/button';
import server from '../utils/server';
import { Avatar } from '../components/avatar';
import { TextLink } from '../components/text';
import { removeToken } from '../utils/auth';


export class NewBot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bot_id: "",
            loaded: false,
            loading: false,
            bots: [],
            title: "",
            description: "",
            support: "",
            error: "",
        };
    }

    componentDidMount() {
        if (!this.props.auth) {
            this.props.setAuth();
            //
        } else if (this.props.loaded && !this.state.loaded) {
            this.getBots();
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.auth && !prevProps.auth) {
            return this.props.getData();
        } else if (!this.props.auth) {
            return this.props.setAuth();
        } else if (this.props.loaded && !this.state.loaded) {
            this.getBots();
        }

    }

    getBots = async function () {
        var response = await server.get("/dashboard/new/bots");
        if (response && response.data.success) {
            if (response.data.bots.length > 0) {
                this.setState({ bot_id: response.data.bots[0].bot_id, bots: response.data.bots, loaded: true });
            } else {
                this.setState({ error: "You do not have any bots to create a panel for. First create a bot on BotGhost.", loaded: true });
            }
        } else {
            this.setState({ error: "There was an error loading your bots.", loaded: true });
        }
    };

    handleLogout = () => {
        // this.props.logout();
        removeToken();
        window.location.href = '/login';
    };

    render() {
        if (this.props.auth && this.props.loaded && this.state.loaded) {
            if (this.state.bots.length == 0) {
                return (
                    <div className='flex justify-center items-center min-h-screen bg-zinc-950 p-4'>
                        <div className='bg-zinc-900 p-6 sm:p-9 rounded-lg w-full max-w-sm sm:max-w-md flex flex-col items-center gap-y-5' >
                            <img className={"size-12 border-none"} src="https://dashboard.botghost.com/images/logo-red.png"></img>
                            <Text color="red">You do not have any bots to create a panel for. You first must go to <TextLink className="text-underline" href="https://botghost.com/" target="_blank">BotGhost</TextLink> and create a bot.</Text>

                            <Divider className="w-full mt-4" />
                            <div className="w-full flex justify-between items-center pt-2">
                                <Text size="sm" color="zinc">Logged in as: {this.props.user.id}</Text>
                                <Button onClick={this.handleLogout} variant="ghost" size="sm">Logout</Button>
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <div className='flex justify-center items-center min-h-screen bg-zinc-950 p-4'>
                    <div className='bg-zinc-900 w-full max-w-sm sm:max-w-md p-6 sm:p-9 rounded-lg'>
                        <form>
                            <Fieldset>
                                <Legend>New BotPanel</Legend>
                                <Text>Create a new BotPanel for your BotGhost bot.</Text>
                                <FieldGroup>
                                    <Field>
                                        <Label>BotGhost Bot</Label>
                                        <Select onChange={(e) => {
                                            this.setState({ bot_id: e.target.value });
                                        }} name="country">
                                            {this.state.bots.map((bot) => {
                                                return (
                                                    <option key={bot.bot_id} value={bot.bot_id}>
                                                        {bot.name}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                        <Description>You may only create BotPanels for BotGhost bot's you own.</Description>
                                        <ErrorMessage>{this.state.error}</ErrorMessage>
                                    </Field>
                                </FieldGroup>

                                <Divider className={"my-5"}></Divider>

                                <FieldGroup disabled={true}>
                                    <Field>
                                        <Label>Title</Label>
                                        <Description>Your panels default page title. If left blank this will be your bots name</Description>
                                        <Input onChange={(e) => {
                                            this.setState({ title: e.target.value });

                                        }} value={this.state.title} />
                                    </Field>

                                    <Field>
                                        <Label>Description</Label>
                                        <Description>Your panels default page description. If left blank this will be your bots name</Description>
                                        <Input onChange={(e) => {
                                            this.setState({ description: e.target.value });

                                        }} value={this.state.description} />
                                    </Field>

                                    <Field>
                                        <Label>Support Server URL</Label>
                                        <Description>A Discord server invite URL for your bot.</Description>
                                        <Input onChange={(e) => {
                                            this.setState({ support: e.target.value });

                                        }} type="url" value={this.state.support} />
                                    </Field>

                                </FieldGroup>

                                <FieldGroup className={"flex"}>
                                    <Button type="button" disabled={
                                        this.state.support.length < 1
                                        || this.state.loading

                                    } className="ml-auto" onClick={async () => {
                                        this.setState({ loading: true });
                                        var response = await server.post("/dashboard/new/save", {
                                            bot_id: this.state.bot_id,
                                            dash_settings: {
                                                title: this.state.title,
                                                description: this.state.description,
                                                support: this.state.support
                                            }
                                        });

                                        if (response && response.data?.success) {
                                            // Redirect to /
                                            window.location.href = "/";
                                        } else if (response.data && !response.data.success) {
                                            this.setState({ error: response.data.error, loading: false });
                                        }

                                    }}>{this.state.loading ? "Creating..." : "Create BotPanel"}</Button>
                                </FieldGroup>
                            </Fieldset>
                        </form>

                        <Divider className="my-6" />
                        <div className="flex justify-between items-center">
                            <Text size="sm" color="zinc">Logged in as: {this.props.user.id}</Text>
                            <Button onClick={this.handleLogout} variant="ghost" size="sm">Logout</Button>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="flex justify-center items-center h-screen bg-zinc-900">
                    <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-white"></div>
                </div>
            );
        }

    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    loaded: state.data.loaded,
    user: state.data.user
});

const mapDispatchToProps = {
    setAuth: setAuth,
    getData: getData,
    // logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBot);