import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import ButtonSettings from './ButtonSettings';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';

class CTASettings extends Component {
    state = {
        layout: 1,
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        image: { src: '', caption: '' },
        buttons: [],
        features: []
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleImageChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            image: { ...prevState.image, [name]: value }
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addFeature = () => {
        this.setState(prevState => ({
            features: [...prevState.features, '']
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleFeatureChange = (index, value) => {
        this.setState(prevState => {
            const updatedFeatures = [...prevState.features];
            updatedFeatures[index] = value;
            return { features: updatedFeatures };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeFeature = (index) => {
        this.setState(prevState => ({
            features: prevState.features.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your CTA section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Centered Layout</option>
                            <option value={2}>Split Layout</option>
                            <option value={3}>Full Width Layout</option>
                            <option value={4}>Feature List Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Image URL</Label>
                        <Description>URL for the CTA image (if applicable)</Description>
                        <Input
                            name="src"
                            value={this.state.image.src}
                            onChange={this.handleImageChange}
                        />
                    </Field>

                    <Field>
                        <Label>Image Caption</Label>
                        <Description>Caption for the CTA image (if applicable)</Description>
                        <Input
                            name="caption"
                            value={this.state.image.caption}
                            onChange={this.handleImageChange}
                        />
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <ButtonSettings
                        buttons={this.state.buttons}
                        onButtonsChange={(updatedButtons) => {
                            this.setState({ buttons: updatedButtons }, () => {
                                if (this.props.updateOptions) {
                                    this.props.updateOptions(this.state);
                                }
                            });
                        }}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Features</Label>
                        <Description>Add feature points for your CTA (if applicable)</Description>
                        {this.state.features.map((feature, index) => (
                            <div key={index} className="mt-4 p-4 border border-gray-300 rounded-md">
                                <Input
                                    value={feature}
                                    onChange={(e) => this.handleFeatureChange(index, e.target.value)}
                                />
                                <Button onClick={() => this.removeFeature(index)} className="mt-4">Remove Feature</Button>
                            </div>
                        ))}
                        <Button onClick={this.addFeature} className="mt-4">Add Feature</Button>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default CTASettings;
