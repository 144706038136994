import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, AlertActions, AlertDescription, AlertTitle } from './alert'; // Assume you have an alert component
import { Button } from './button';
import history from '../utils/history'; // Import history

// Define the tier order based on Pricing.js
const tierOrder = ['free', 'basic', 'hobby', 'pro'];

export class PremiumModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: false,
        };
    }

    handleClick = (e) => {
        const { bot, requiredType } = this.props;
        const currentTier = bot?.subscription?.type || 'free';
        const currentTierIndex = tierOrder.indexOf(currentTier);
        const requiredTierIndex = tierOrder.indexOf(requiredType);

        if (currentTierIndex < requiredTierIndex) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ showAlert: true });
        }
    };

    closeAlert = () => {
        this.setState({ showAlert: false });
    };

    render() {
        const { bot, requiredType, children } = this.props;
        const currentTier = bot?.subscription?.type || 'free';
        const currentTierIndex = tierOrder.indexOf(currentTier);
        const requiredTierIndex = tierOrder.indexOf(requiredType);
        const isDisabled = currentTierIndex < requiredTierIndex;

        return (
            <div
                onClick={this.handleClick}
                className={`${isDisabled ? 'cursor-not-allowed opacity-70' : ''}`}
            >
                <div className={isDisabled ? 'pointer-events-none' : ''}>
                    {children}
                </div>

                <Alert open={this.state.showAlert} onClose={this.closeAlert}>
                    <AlertTitle>Premium Content Required</AlertTitle>
                    <AlertDescription>
                        To access this content, you need a {requiredType} subscription or higher.
                    </AlertDescription>
                    <AlertActions>
                        <Button plain onClick={this.closeAlert}>
                            Close
                        </Button>
                        <Button color="blue" onClick={() => history.push('/pricing')}>
                            Upgrade Now
                        </Button>
                    </AlertActions>
                </Alert>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    bot: state.data.bot ? state.data.bot : {},
    // Add any state mappings if needed
});

export default connect(mapStateToProps)(PremiumModal);
