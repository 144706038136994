import React, { Component } from 'react';
import { Select } from '../../components/select';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';

class CommentsSettings extends Component {
    state = {
        layout: 1
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your comments section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={this.handleChange}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Default Layout</option>
                            <option value={2}>Alternate Layout</option>
                            <option value={3}>Compact Layout</option>
                        </Select>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default CommentsSettings;
