import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';


class PricingSettings extends Component {
    state = {
        layout: 1,
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        plans: []
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState(this.props.options);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handlePlanChange = (index, field, value) => {
        this.setState(prevState => {
            const updatedPlans = [...prevState.plans];
            updatedPlans[index] = { ...updatedPlans[index], [field]: value };
            return { plans: updatedPlans };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addPlan = () => {
        this.setState(prevState => ({
            plans: [...prevState.plans, { name: '', price: '', features: [] }]
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removePlan = (index) => {
        this.setState(prevState => ({
            plans: prevState.plans.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addFeature = (planIndex) => {
        this.setState(prevState => {
            const updatedPlans = [...prevState.plans];
            updatedPlans[planIndex].features.push('');
            return { plans: updatedPlans };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleFeatureChange = (planIndex, featureIndex, value) => {
        this.setState(prevState => {
            const updatedPlans = [...prevState.plans];
            updatedPlans[planIndex].features[featureIndex] = value;
            return { plans: updatedPlans };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeFeature = (planIndex, featureIndex) => {
        this.setState(prevState => {
            const updatedPlans = [...prevState.plans];
            updatedPlans[planIndex].features = updatedPlans[planIndex].features.filter((_, i) => i !== featureIndex);
            return { plans: updatedPlans };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your pricing section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Default Layout</option>
                            <option value={2}>Compact Layout</option>
                            <option value={3}>Featured Layout</option>
                        </Select>
                    </Field>

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Pricing Plans</Label>
                        <Description>Add or edit pricing plans</Description>
                        {this.state.plans.map((plan, planIndex) => (
                            <div key={planIndex} className="mt-4 p-4 border border-gray-300 rounded-md">
                                <Field>
                                    <Label>Plan Name</Label>
                                    <Input
                                        value={plan.name || ''}
                                        onChange={(e) => this.handlePlanChange(planIndex, 'name', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Price</Label>
                                    <Input
                                        value={plan.price || ''}
                                        onChange={(e) => this.handlePlanChange(planIndex, 'price', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Features</Label>
                                    {plan.features.map((feature, featureIndex) => (
                                        <div key={featureIndex} className="flex items-center mt-2">
                                            <Input
                                                value={feature}
                                                onChange={(e) => this.handleFeatureChange(planIndex, featureIndex, e.target.value)}
                                                className="flex-grow"
                                            />
                                            <Button onClick={() => this.removeFeature(planIndex, featureIndex)} className="ml-2">Remove</Button>
                                        </div>
                                    ))}
                                    <Button onClick={() => this.addFeature(planIndex)} className="mt-2">Add Feature</Button>
                                </Field>
                                <Button onClick={() => this.removePlan(planIndex)} className="mt-4">Remove Plan</Button>
                            </div>
                        ))}
                        <Button onClick={this.addPlan} className="mt-4">Add Plan</Button>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default PricingSettings;
