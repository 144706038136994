import React, { Component } from 'react';
import { Input } from '../../components/input';
import { Button } from '../../components/button';
import { Field, FieldGroup, Fieldset, Label, Description } from '../../components/fieldset';
import { Select } from '../../components/select';
import TitleDescriptionSettings from '../../components/TitleDescriptionSettings';

class StatisticsSettings extends Component {
    state = {
        layout: 1,
        pretitle: '',
        pretitleSize: 'text-base',
        pretitleColor: '#FFFFFF',
        title: '',
        titleSize: 'text-2xl',
        titleColor: '#FFFFFF',
        description: '',
        descriptionSize: 'text-base',
        descriptionColor: '#FFFFFF',
        textJustification: 'left',
        stats: [],
        layout2BorderColor: '#374151', // Default border color for Layout 2
        layout3BackgroundColor: '#1F2937', // Default background color for Layout 3
        layout3HighlightColor: '#3B82F6' // Default highlight color for Layout 3
    };

    componentDidMount() {
        if (this.props.options) {
            this.setState({
                ...this.props.options,
                stats: this.props.options.stats || []
            });
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    handleStatChange = (index, field, value) => {
        this.setState(prevState => {
            const updatedStats = [...prevState.stats];
            updatedStats[index] = { ...updatedStats[index], [field]: value };
            return { stats: updatedStats };
        }, () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    addStat = () => {
        this.setState(prevState => ({
            stats: [...prevState.stats, { label: '', value: '', icon: '' }]
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    removeStat = (index) => {
        this.setState(prevState => ({
            stats: prevState.stats.filter((_, i) => i !== index)
        }), () => {
            if (this.props.updateOptions) {
                this.props.updateOptions(this.state);
            }
        });
    };

    renderColorPicker = (label, field, value) => (
        <Field>
            <Label>{label}</Label>
            <div className="flex items-center">
                <input
                    type="color"
                    value={value}
                    onChange={(e) => this.handleChange(field, e.target.value)}
                    className="w-10 h-10 mr-2"
                />
                <Input
                    value={value}
                    onChange={(e) => this.handleChange(field, e.target.value)}
                    placeholder="#RRGGBB"
                />
            </div>
        </Field>
    );

    render() {
        return (
            <Fieldset>
                <FieldGroup>
                    <Field>
                        <Label>Layout</Label>
                        <Description>Choose the layout for your statistics section</Description>
                        <Select
                            name="layout"
                            value={this.state.layout}
                            onChange={(e) => this.handleChange('layout', parseInt(e.target.value))}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            <option value={1}>Simple Grid Layout</option>
                            <option value={2}>Bordered Card Layout</option>
                            <option value={3}>Horizontal Card Layout</option>
                        </Select>
                    </Field>

                    {this.state.layout === 2 && (
                        this.renderColorPicker('Border Color', 'layout2BorderColor', this.state.layout2BorderColor)
                    )}

                    {this.state.layout === 3 && (
                        <>
                            {this.renderColorPicker('Background Color', 'layout3BackgroundColor', this.state.layout3BackgroundColor)}
                            {this.renderColorPicker('Highlight Color', 'layout3HighlightColor', this.state.layout3HighlightColor)}
                        </>
                    )}

                    <hr className="my-6 border-t border-gray-700" />

                    <TitleDescriptionSettings
                        settings={this.state}
                        onChange={this.handleChange}
                    />

                    <hr className="my-6 border-t border-gray-700" />

                    <Field>
                        <Label>Statistics</Label>
                        <Description>Add or edit statistics</Description>
                        {this.state.stats.map((stat, index) => (
                            <div key={index} className="mt-4 p-4 border border-gray-300 rounded-md">
                                <Field>
                                    <Label>Label</Label>
                                    <Input
                                        value={stat.label || ''}
                                        onChange={(e) => this.handleStatChange(index, 'label', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Value</Label>
                                    <Input
                                        value={stat.value || ''}
                                        onChange={(e) => this.handleStatChange(index, 'value', e.target.value)}
                                    />
                                </Field>
                                <Field className="mt-2">
                                    <Label>Icon (optional)</Label>
                                    <Input
                                        value={stat.icon || ''}
                                        onChange={(e) => this.handleStatChange(index, 'icon', e.target.value)}
                                        placeholder="FontAwesome icon name"
                                    />
                                </Field>
                                <Button onClick={() => this.removeStat(index)} className="mt-4">Remove Statistic</Button>
                            </div>
                        ))}
                        <Button onClick={this.addStat} className="mt-4">Add Statistic</Button>
                    </Field>
                </FieldGroup>
            </Fieldset>
        );
    }
}

export default StatisticsSettings;
